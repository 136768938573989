import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { PageParams } from '../../../data/api';
import { Org, PaginatedData, StaffMember, Student } from '../../../data/classes';
import { fullname } from '../../../data/functions';
import { useRequests } from '../../../data/requests';
import { ICON_STUDENT } from '../../../theme';
import { useAccount } from '../../Account';
import { Loading } from '../../loading';
import { ModalContext } from '../../Modal';
import { StudentModal } from '../../modal/student';
import { AnalyticaTable } from '../../table';
import { ActionsMenu } from '../../table/action';
import { ActionMenuItem } from '../../table/action';
import { RTableColumn } from '../../table/column';
import { RTableColumnProps } from '../../table/column/r-table-column';

export const Students = () => {

  const { getCognitoId } = useAccount()
  const { setModalContent } = useContext(ModalContext)
  const { fetchOrgById, fetchStaffMemberByCognitoId, fetchStudentsByStaffMemberId } = useRequests()
  const [staffMember, setStaffMember] = useState<StaffMember>()
  const [org, setOrg] = useState<Org>()

  const load = useCallback(async () => {
    const cognitoId = await getCognitoId()
    if (!cognitoId) {
      throw new Error("CognitoId Not Found")
    }
    const staffMember = await fetchStaffMemberByCognitoId(cognitoId, setStaffMember)
    fetchOrgById(staffMember.orgId, setOrg)
  }, [getCognitoId, fetchOrgById, fetchStaffMemberByCognitoId]);

  useEffect(() => {
    load()
  }, [load])

  if (!org || !staffMember) {
    return <Loading />
  }

  const fetchStudents = (setItems: (p: PaginatedData<Student>) => void, pageParams: PageParams) => {
    fetchStudentsByStaffMemberId(staffMember.id, setItems, pageParams);
  }

  const fullnameComponent = (_column: ReactElement<RTableColumnProps>, s: Student) => {
    return fullname(s)
  }

  return (
    <AnalyticaTable
      title='Students'
      icon={ICON_STUDENT}
      fetchItems={fetchStudents}
      defaultLimit={20}
      searchable
      searchOnChange
      fullscreen
    >
      <ActionsMenu>
        <ActionMenuItem
          onClick={() => setModalContent(
            <StudentModal
              mode='add'
              org={org}
              callback={load}
            />
          )}
          testId="new-student-button"
        >New Student</ActionMenuItem>
      </ActionsMenu>

      <RTableColumn id="name" name="Name" component={fullnameComponent} />
      <RTableColumn id="level" name="Level" />
      <RTableColumn id="yearLevel" name="yearLevel" />
      <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
      <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
    </AnalyticaTable>

  )
}

