import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import React, { ReactElement } from 'react';
import { BORDER_DEFAULT, BORDER_PRIMARY_DARK, COLOUR_PRIMARY_DARK, COLOUR_PRIMARY_WHITE, MARGIN_LARGE, MARGIN_SMALL, RADIUS_PRIMARY } from "../../theme";
import { AnalyticaTableTabProps } from "./analytica-table";

interface Props {
  children: ReactElement<AnalyticaTableTabProps> | ReactElement<AnalyticaTableTabProps>[]
  hideSingleTab?: boolean
}

export const AnalyticaTableContainer: React.FC<Props> = ({
  hideSingleTab = false,
  ...props
}) => {

  const last = React.Children.count(props.children) - 1;

  return (
    <Box
      borderRadius={RADIUS_PRIMARY}
    >
      <Tabs
        variant="enclosed"
        isLazy
      >
        {
          ((Array.isArray(props.children) && props.children.length > 1) || !hideSingleTab) && <TabList>
            {React.Children.map(props.children, (child: ReactElement<AnalyticaTableTabProps>, index: number) =>
              <Tab
                color={COLOUR_PRIMARY_DARK}
                bg={COLOUR_PRIMARY_WHITE}
                border={BORDER_DEFAULT}
                borderTopLeftRadius={index === 0 ? RADIUS_PRIMARY : 0}
                borderTopRightRadius={index === last ? RADIUS_PRIMARY : 0}
                _selected={
                  {
                    color: COLOUR_PRIMARY_WHITE,
                    bg: COLOUR_PRIMARY_DARK,
                    border: BORDER_PRIMARY_DARK,
                  }
                }
                test-id={child.props.testId}
              >
                <Box
                  px={MARGIN_LARGE}
                  py={MARGIN_SMALL}
                >
                  <Text fontSize="sm">
                    {child.props.title}
                  </Text>
                </Box>
              </Tab>
            )}
          </TabList>
        }

        <TabPanels
        >
          {
            React.Children.map(props.children,
              (child: ReactElement<AnalyticaTableTabProps>) =>
                <TabPanel
                  // The next lineneeds to be here for some reason or it goes all fucky?
                  p={0}
                >
                  {child}
                </TabPanel>)}
        </TabPanels>
      </Tabs>

      <Box h={5} />

    </Box >
  )
}
