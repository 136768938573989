import { Box, Center, Text } from "@chakra-ui/react";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { validateNonEmpty } from "../data/validations";
import { useAccount } from "./Account";
import { ResetPasswordButton, SendCodeButton } from "./button";
import { AuthForm } from "./form";
import { ConfirmPasswordInput, EmailInput, PasswordInput, TextInput } from "./form/input";

export const ForgotPassword = () => {

  const navigate = useNavigate()
  const [error, setError] = useState<string | null>(null)
  const [page, setPage] = useState<string>('send-code')
  const { forgotPassword, confirmNewPassword } = useAccount()

  const navigateToLogin = () => {
    navigate("/login", { replace: true });
  }

  const onError = (err: Error) => {
    setError(err.name === "InvalidParameterException" ? "Error Resetting Password" : "Error Resetting Password")
  }

  const onSubmit = async (values: any) => {
    if (page === 'send-code') {
      try {
        await forgotPassword(values.email)
        setPage('reset-password')
      } catch (err: any) {
        onError(err)
      }
    } else if (page === 'reset-password') {
      try {
        await confirmNewPassword(values.email, values.verificationCode, values.password)
        setPage('success-msg')
      } catch (err: any) {
        onError(err)
      }
    } else {
      throw new Error("page not handled")
    }
  }

  return (
    <AuthForm
      name="forgotPassword"
      title={page === 'success-msg' ? "Password Reset Success!" : "Forgot your Password?"}
      error={error}
      onSubmit={onSubmit}
    >
      {
        page === 'send-code' &&
        <>
          <EmailInput testId="email" />
          <Box pt={4} />
          <SendCodeButton testId="send-code-button" />
        </>
      }
      {
        page === 'reset-password' &&
        <>
          <Center>
            <Text align="center" opacity="80%" size="sm">You should now receive an email with a verification code.</Text>
          </Center>
          <TextInput
            name="verificationCode"
            title="Verification Code"
            testId="verification-code-input"
            validation={validateNonEmpty}
          />
          <PasswordInput
            title="New Password"
            testId="new-password-input"
            w="full" />
          <ConfirmPasswordInput
            title="Confirm New Password"
            testId="confirm-new-password-input"
            w="full" />
          <Box pt={4} />
          <ResetPasswordButton w="full" testId="reset-password-button" />
        </>
      }
      {
        page === 'success-msg' &&
        <>
          <Center>
            <Text
              align="center"
              opacity="80%"
              size="sm"
            >Success! Your Password has been Reset. Click the button below to Login.</Text>
          </Center>
          <Box pt={4} />
          <Text
            fontSize="sm"
            onClick={navigateToLogin}
            textDecoration="none"
            _hover={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            test-id="go-to-log-in-button"
          >Login</Text>
        </>
      }
    </AuthForm >
  )
}
