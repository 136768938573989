import React from "react";
import { PageParams } from "../../../../data/api";
import { PaginatedData, Student } from "../../../../data/classes";
import { fullname } from '../../../../data/functions';
import { useRequests } from "../../../../data/requests";
import { ICON_STAFF } from "../../../../theme";
import { AnalyticaTable } from "../../../table";
import { RTableColumn } from "../../../table/column";
import { StaffMemberTableTabProps } from "../staff-member-profile";

export const StaffMemberStudents: React.FC<StaffMemberTableTabProps> = ({ ...props }) => {

  const { fetchStudentsByStaffMemberId } = useRequests()

  const fetchStudents = (setItems: (p: PaginatedData<Student>) => void, pageParams: PageParams) => {
    fetchStudentsByStaffMemberId(props.staffMember.id, setItems, pageParams);
  }

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchStudents}
        searchable
        searchOnChange
        sort="preferredName"
        to={"/students"}
      >
        <RTableColumn id="name" name="Name" component={fullname} />
        <RTableColumn id="Level" name="Level" />
        <RTableColumn id="yearLevel" name="Year Level" />
        <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
        <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
      </AnalyticaTable>
    </>
  )
}
