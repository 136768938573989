import { Box, Grid, GridItem } from "@chakra-ui/react";
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { BORDER_DEFAULT, COLOUR_PRIMARY_LIGHT, MARGIN_LARGE } from "../theme";
import { useAccount } from "./Account";
import { Sidebar } from "./sidebar";

const R = require('ramda');

interface Props {
  roles: string[]
}

/*
 * This function is used to prevent the user from accessing 
 * pages that they do not have the permissions to access.
 */
export const ProtectedRoute: React.FC<Props> = ({ roles }) => {

  const navigate = useNavigate();
  const { isSessionValid, getUserGroups } = useAccount()
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  const containsAtLeastOne = (userRoles: string[], requiredRoles: string[]) => {
    return R.intersection(userRoles, requiredRoles).length > 0;
  }

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const hasValidSession = await isSessionValid();
        if (!hasValidSession) {
          throw new Error("No Valid Session")
        }
        const userGroups = await getUserGroups();
        if (!userGroups || !containsAtLeastOne(userGroups, roles)) {
          navigate("/404", { replace: true });
        } else {
          setIsAuthorized(true);
        }
      } catch (error) {
        navigate("/login", { replace: true }); // Invalid session case
      }
    };
    checkAuthorization();
  }, [isSessionValid, getUserGroups, roles, navigate]);

  // Prevent rendering the page until authorization is determined
  if (!isAuthorized) return null;

  return (
    <Grid
      minHeight='100vh'
      templateColumns='repeat(10, 1fr)'
      bg={COLOUR_PRIMARY_LIGHT}
    >
      <GridItem colSpan={1} bg='white' borderRight={BORDER_DEFAULT}>
        <Sidebar />
      </GridItem>
      <GridItem colSpan={9}>
        <Box m={MARGIN_LARGE}>
          <Outlet />
        </Box>
      </GridItem>
    </Grid>
  )
};
