import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import React, { createContext, ReactNode, useContext, useState } from "react";

declare const window: any;

interface AccountContextType {
  signIn: (username: string, password: string) => Promise<void>;
  completeNewPasswordChallenge: (password: string) => Promise<void>;
  signOut: () => void;
  confirmRegistration: (username: string, code: string) => Promise<void>;
  resendConfirmationCode: (username: string) => Promise<void>;
  forgotPassword: (username: string) => Promise<void>;
  confirmNewPassword: (username: string, code: string, newPassword: string) => Promise<void>;
  isSessionValid: () => Promise<boolean>;
  getToken: () => Promise<string | null>;
  getCognitoId: () => Promise<string | null>;
  getUserGroups: () => Promise<string[]>;
  getUserAttributes: () => Promise<{ email?: string, firstName?: string; lastName?: string } | null>;
  updateUserAttributes: (firstName: string, lastName: string) => Promise<void>;
}

export const AccountContext = createContext<AccountContextType>({} as AccountContextType);

const poolData = {
  ClientId: process.env.NODE_ENV === 'production' ? window.env.COGNITO_CLIENT_ID : process.env.REACT_APP_COGNITO_CLIENT_ID,
  UserPoolId: process.env.NODE_ENV === 'production' ? window.env.COGNITO_USER_POOL_ID : process.env.REACT_APP_COGNITO_USER_POOL_ID
}
const userPool = new CognitoUserPool(poolData);

export interface AccountProps {
  children: ReactNode | ReactNode[]
}

export const Account: React.FC<AccountProps> = (props: AccountProps) => {

  // NOTE: This cognitoUser is only used when user must complete reset password challenge
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>()

  // Sign In method
  const signIn = async (username: string, password: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
      const authDetails = new AuthenticationDetails({ Username: username, Password: password });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (_session) => {
          resolve();
        },
        onFailure: (err: Error) => reject(err),
        newPasswordRequired: (_userAttributes) => {
          // Store the user so the password can be changed later
          setCognitoUser(cognitoUser)
          reject({ type: "NEW_PASSWORD_REQUIRED" });
        },
      });
    });
  };

  // Complete new password challenge
  const completeNewPasswordChallenge = async (newPassword: string): Promise<void> => {
    if (!cognitoUser) throw new Error("No user in context");
    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
        onSuccess: (_session) => {
          resolve();
        },
        onFailure: (err) => reject(err),
      });
    });
  };

  // Sign Out
  const signOut = () => {
    const user = userPool.getCurrentUser();
    user?.signOut();
  };

  // Confirm Registration
  const confirmRegistration = (username: string, code: string) => {
    return new Promise<void>((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
      cognitoUser.confirmRegistration(code, true, (err, _result) => {
        if (err) reject(err);
        else resolve();
      });
    });
  };

  // Resend Confirmation Code
  const resendConfirmationCode = (username: string) => {
    return new Promise<void>((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
      cognitoUser.resendConfirmationCode((err, _result) => {
        if (err) reject(err);
        else resolve();
      });
    });
  };

  // Forgot Password
  const forgotPassword = (username: string) => {
    return new Promise<void>((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
      cognitoUser.forgotPassword({
        onSuccess: () => resolve(),
        onFailure: (err) => reject(err),
      });
    });
  };

  // Confirm New Password
  const confirmNewPassword = (username: string, code: string, newPassword: string) => {
    return new Promise<void>((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: () => resolve(),
        onFailure: (err) => reject(err),
      });
    });
  };

  const isSessionValid = async (): Promise<boolean> => {
    return new Promise((resolve) => {
      const user = userPool.getCurrentUser();
      if (!user) {
        return resolve(false);
      }
      // get session
      user.getSession((err: Error, session: CognitoUserSession | null) => {
        if (err || !session?.isValid()) return resolve(false);
        resolve(true);
      });
    });
  };

  // Get Auth Token
  const getToken = async (): Promise<string | null> => {
    return new Promise((resolve, _reject) => {
      const user = userPool.getCurrentUser();
      if (!user) return resolve(null);
      // get session
      user.getSession((err: Error, session: CognitoUserSession | null) => {
        if (err || !session?.isValid()) return resolve(null);
        resolve(session?.getIdToken().getJwtToken());
      });
    });
  };

  const getCognitoId = async (): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser();
      // get session
      user?.getSession((err: Error, session: CognitoUserSession | null) => {
        if (err) {
          reject(err);
          return;
        }
        // The 'Sub' claim in the ID Token is the Cognito User ID
        const userId = session?.getIdToken().payload.sub;
        resolve(userId);
      });
    });
  };

  const getUserGroups = async (): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser();
      if (!user) resolve([])
      // get session
      user?.getSession((err: Error, session: CognitoUserSession | null) => {
        if (err) {
          reject(err);
          return;
        }
        // Extract the 'cognito:groups' claim from the ID Token
        const groups = session?.getIdToken().payload["cognito:groups"];
        resolve(groups || []); // Return groups or an empty array if not present
      });
    });
  };

  const getUserAttributes = async (): Promise<{ email?: string, firstName?: string; lastName?: string } | null> => {
    return new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser();
      if (!user) return resolve(null);
      // get session
      user.getSession((err: Error, session: CognitoUserSession | null) => {
        if (err || !session?.isValid()) return resolve(null);
        // get attributes
        user.getUserAttributes((err, attributes) => {
          if (err) return reject(err);
          const email = attributes?.find(attr => attr.getName() === "email")?.getValue();
          const firstName = attributes?.find(attr => attr.getName() === "given_name")?.getValue();
          const lastName = attributes?.find(attr => attr.getName() === "family_name")?.getValue();
          resolve({ email, firstName, lastName });
        });
      });
    });
  };

  const updateUserAttributes = async (firstName: string, lastName: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser();
      if (!user) return reject(new Error("No authenticated user"));
      // get session
      user.getSession((err: Error, session: CognitoUserSession | null) => {
        if (err || !session?.isValid()) return reject(new Error("Invalid session"));
        // update user attributes
        user.updateAttributes(
          [
            { Name: "given_name", Value: firstName },
            { Name: "family_name", Value: lastName },
          ],
          (err, _result) => {
            if (err) reject(err);
            else resolve();
          }
        );
      });
    });
  };

  return (
    <AccountContext.Provider value={{
      signIn,
      completeNewPasswordChallenge,
      signOut,
      confirmRegistration,
      resendConfirmationCode,
      forgotPassword,
      confirmNewPassword,
      isSessionValid,
      getToken,
      getCognitoId,
      getUserGroups,
      getUserAttributes,
      updateUserAttributes
    }}>
      {props.children}
    </AccountContext.Provider>
  )
}

export const useAccount = (): AccountContextType => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccount must be used within a AccountProvider');
  }
  return context;
}
