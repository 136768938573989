import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageParams } from '../../../data/api';
import { Class, Org, PaginatedData, StaffMember, Student } from '../../../data/classes';
import { fullname } from '../../../data/functions';
import { useRequests } from '../../../data/requests';
import { validateMultiSelection, validateName, validateNonEmpty } from '../../../data/validations';
import { AnalyticaForm } from '../../form';
import { IntegerInput, MultiSelectionInput, TextInput } from '../../form/input';
import { ModalMode } from '../../Modal';

const R = require('ramda')

export type StudentModalMode = ModalMode | 'classes';

export interface StudentModalProps {
  mode: StudentModalMode
  org: Org
  clazz?: Class
  student?: Student
  staffMember?: StaffMember
  callback?: () => void
}

export const StudentModal: React.FC<StudentModalProps> = ({ ...props }) => {

  const navigate = useNavigate()

  const deleteAndReturn = async (student: Student, callback: () => void) => {
    deleteStudent(student, callback)
    navigate(-1)
  }

  const {
    postStudents,
    deleteStudent,
    fetchClassesByOrgId,
    fetchClassesByStaffMemberId,
    fetchClassesByStudentId,
    patchStudentClasses,
    putStudent
  } = useRequests()

  const fetchClassesForStudent = (setItems: (p: PaginatedData<Class>) => void, pageParams: PageParams) => {
    if (props.student === undefined) {
      throw new Error("Student is undefined, cannot fetch classes")
    }
    fetchClassesByStudentId(props.student.id, setItems, pageParams);
  }

  const fetchDropdownClasses = (setItems: (p: PaginatedData<Class>) => void, pageParams: PageParams) => {
    if (props.staffMember) {
      fetchClassesByStaffMemberId(props.staffMember.id, setItems, pageParams);
    } else {
      fetchClassesByOrgId(props.org.id, setItems, pageParams);
    }
  }

  const postStudent = (s: Student, callback: () => void) => {
    postStudents([s], callback)
  }

  const patchStudentClassArray = async (s: Student, callback: () => void) => {
    if (!props.student) {
      throw new Error("Student prop not supplied")
    }
    patchStudentClasses(props.student.id, s.classes, callback)
  }

  let title = ""
  let description = ""
  let initialValues = {}

  switch (props.mode) {
    case "add":
      title = "Add a new Student to "
      description = props.clazz ? `Add a new Student to ${props.clazz.name} at ${props.org.name}.`
        : `Add a new Student at ${props.org.name}.`
      initialValues = {
        preferredName: "",
        lastName: "",
        identifier: "",
        yearLevel: "",
        level: 1,
        orgId: props.org.id,
        staff: props.staffMember && [props.staffMember.id],
        classes: props.clazz && [props.clazz.id]
      }
      break
    case "update":
      if (!props.student) {
        throw new Error("Item not supplied");
      }
      title = "Edit Student"
      description = "Update Student Details."
      initialValues = props.student
      break
    case "delete":
      if (!props.student) {
        throw new Error("Item not supplied");
      }
      title = "Delete Student"
      description = `Are you sure you want to delete Student - ${fullname(props.student)}?`
      initialValues = { id: props.student.id }
      break
    case "classes":
      if (!props.student) {
        throw new Error("Item not supplied");
      }
      title = "Edit Student Classes"
      description = `Update the assigned Classes for Student - ${fullname(props.student)}?`
      initialValues = { classes: props.student.classes }
      break
  }

  return (
    <AnalyticaForm
      name='student-modal'
      title={title}
      description={description}
      initialValues={initialValues}
      mode={props.mode === 'classes' ? 'update' : props.mode}
      onSubmit={
        props.mode === 'add' ? postStudent
          : props.mode === 'update' ? putStudent
            : props.mode === 'delete' ? deleteAndReturn
              : patchStudentClassArray}
      callback={props.callback}
    >
      {
        R.includes(props.mode, ['add', 'update']) &&
        <AnalyticaForm.SingleItemForm>
          <TextInput
            name="preferredName"
            title="Preferred Name"
            testId='student-preferred-name-input'
            validation={validateName}
          />
          <TextInput
            name="lastName"
            title="Last Name"
            testId='student-last-name-input'
            validation={validateName}
          />
          <TextInput
            name="identifier"
            title="Identifier"
            testId='student-identifier-input'
            validation={validateName}
          />
          <IntegerInput
            name="yearLevel"
            title="Year Level"
            testId='student-year-level-input'
            min={1}
            max={12}
            validation={validateNonEmpty}
          />
          {
            props.mode === 'update' &&
            <IntegerInput
              name="level"
              title="Student Level"
              testId='student-level-input'
              min={1}
              max={20}
              validation={validateNonEmpty}
            />
          }
        </AnalyticaForm.SingleItemForm>
      }
      {
        props.mode === 'classes' &&
        <AnalyticaForm.SingleItemForm>
          <MultiSelectionInput
            name="classes"
            title="Classes"
            testId='student-class-input'
            validation={validateMultiSelection}
            initItems={fetchClassesForStudent}
            fetchItems={fetchDropdownClasses}
          />
        </AnalyticaForm.SingleItemForm>
      }
    </AnalyticaForm>
  )
}
