import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Admin } from "../../../data/classes";
import { fullname } from "../../../data/functions";
import { useRequests } from "../../../data/requests";
import { Loading } from "../../loading";
import { ModalContext } from "../../Modal";
import { AdminModal } from "../../modal/admin";
import { ActionsMenu } from "../../table/action";
import { ActionMenuItem } from "../../table/action/action-menu-item";
import { AnalyticaTableTabProps } from "../../table/analytica-table";
import { RAProfile } from "../profile";

export interface AdminTableTabProps extends AnalyticaTableTabProps {
  admin: Admin
}

export const AdminProfile = () => {

  const { fetchAdminById } = useRequests()
  const { setModalContent } = useContext(ModalContext)
  const [admin, setAdmin] = useState<Admin | null>()
  let { adminId } = useParams();

  const load = useCallback(() => {
    if (adminId) {
      fetchAdminById(adminId, setAdmin)
    }
  }, [adminId, fetchAdminById])

  useEffect(() => {
    load()
  }, [load]);

  if (!admin) {
    return <Loading />
  }

  return (
    <RAProfile
      primary={fullname(admin) || ''}
      secondary={admin.email}
    >
      <ActionsMenu>
        <ActionMenuItem testId="edit" onClick={() => setModalContent(<AdminModal mode="update" admin={admin} />)}>Edit</ActionMenuItem>
        <ActionMenuItem testId="delete" onClick={() => setModalContent(<AdminModal mode="delete" admin={admin} />)}>Delete</ActionMenuItem>
      </ActionsMenu>
    </RAProfile>
  )
}
