import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { Admin } from '../../../data/classes';
import { fullname } from '../../../data/functions';
import { useRequests } from '../../../data/requests';
import { ICON_ADMIN } from '../../../theme';
import { ModalContext } from '../../Modal';
import { AdminModal } from '../../modal/admin';
import { AnalyticaTable } from '../../table';
import { ActionsMenu } from '../../table/action';
import { ActionMenuItem } from '../../table/action';
import { RTableColumn } from '../../table/column';
import { RTableColumnProps } from '../../table/column/r-table-column';

export const Admins = () => {

  const { setModalContent } = useContext(ModalContext)
  const { fetchAdmins } = useRequests()
  const [reloadKey, setReloadKey] = useState(0)

  const load = useCallback(() => {
    setReloadKey(reloadKey + 1)
  }, [reloadKey])

  useEffect(() => {
    load()
  }, [load])

  const fullnameComponent = (_column: ReactElement<RTableColumnProps>, a: Admin) => {
    return fullname(a)
  }

  return (
    <AnalyticaTable
      title='Admins'
      icon={ICON_ADMIN}
      fetchItems={fetchAdmins}
      defaultLimit={20}
      searchable
      searchOnChange
      fullscreen
    >
      <ActionsMenu>
        <ActionMenuItem
          testId="new-admin-button"
          onClick={() => setModalContent(
            <AdminModal
              mode='add'
              callback={load}
            />
          )}
        >New Admin</ActionMenuItem>
      </ActionsMenu>

      <RTableColumn id="name" name="Name" component={fullnameComponent} />
      <RTableColumn id="email" name="Email Address" />

    </AnalyticaTable>
  )
}
