import { Tag } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';
import { PageParams } from '../../../data/api';
import { Org, PaginatedData, StaffMember, Test } from '../../../data/classes';
import { datetimeTitle } from '../../../data/functions';
import { useRequests } from '../../../data/requests';
import { BORDER_DEFAULT, ICON_TEST } from '../../../theme';
import { useAccount } from '../../Account';
import { Loading } from '../../loading/loading';
import { AnalyticaTable } from '../../table/analytica-table';
import { RTableColumn } from '../../table/column';
import { RTableColumnProps } from '../../table/column/r-table-column';

export const Tests = () => {

  const { fetchOrgById, fetchStaffMemberByCognitoId, fetchTestsByStaffMemberId } = useRequests()
  const { getCognitoId } = useAccount()
  const [org, setOrg] = useState<Org>()
  const [staffMember, setStaffMember] = useState<StaffMember>()

  useEffect(() => {
    const load = async () => {
      const cognitoId = await getCognitoId()
      if (!cognitoId) {
        throw new Error("CognitoId Not Found")
      }
      const staffMember = await fetchStaffMemberByCognitoId(cognitoId, setStaffMember)
      fetchOrgById(staffMember.orgId, setOrg)
    }
    load()
  }, [getCognitoId, fetchOrgById, fetchStaffMemberByCognitoId]);

  if (!org || !staffMember) {
    return <Loading />
  }

  const fetchTests = (setItems: (p: PaginatedData<Test>) => void, pageParams: PageParams) => {
    fetchTestsByStaffMemberId(staffMember.id, setItems, pageParams);
  }

  const testTitleComponent = (_column: ReactElement<RTableColumnProps>, t: Test) => {
    return datetimeTitle(t)
  }

  const testStatus = (t: Test) =>
    <Tag
      border={BORDER_DEFAULT}
      bg={t.completed ? "#F0FFF4" : "#FFF5EE"}
    >{t.completed ? "COMPLETED" : "PENDING"}</Tag>

  return (
    <AnalyticaTable
      title='Tests'
      icon={ICON_TEST}
      fetchItems={fetchTests}
      defaultLimit={20}
      fullscreen
    >
      <RTableColumn id="created" name="Created" component={testTitleComponent} />
      <RTableColumn id="status" name="Status" component={testStatus} />
      <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
      <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
    </AnalyticaTable>
  )
}

