import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import { Org } from "../../../data/classes";
import { Loading } from "../../loading";
import { AnalyticaTableContainer } from "../../table/analytica-table-container";
import { AnalyticaTableTabProps } from "../../table/analytica-table";
import { OrgStaff } from "./staff";
import { OrgStudents } from "./students";
import { OrgModal } from "../../modal/org";
import { RAProfile } from "../profile";
import { StatTile } from "../../stats";
import { ICON_CLASS, ICON_STAFF, ICON_STUDENT } from "../../../theme";
import OrgClasses from "./classes/org-classes";
import { useRequests } from "../../../data/requests";
import { useAccount } from "../../Account";
import { ModalContext } from "../../Modal";
import { ActionMenuItem } from "../../table/action/action-menu-item";
import { ActionsMenu } from "../../table/action";

export interface OrgTableTabProps extends AnalyticaTableTabProps {
  org: Org
}

export const OrgProfile = () => {

  const { getCognitoId, getUserGroups } = useAccount()
  const { fetchOrgById, fetchStaffMemberByCognitoId } = useRequests()
  const { setModalContent } = useContext(ModalContext)
  const [org, setOrg] = useState<Org | null>()
  let { orgId } = useParams();

  const load = useCallback(async () => {
    // fetch cognito id
    const cognitoId = await getCognitoId()
    if (!cognitoId) {
      throw new Error("CognitoId Not Found")
    }
    const userGroups = await getUserGroups()
    if (userGroups.includes("admin")) {
      // nothing here, since admins can view all orgs
    } else if (userGroups.includes("staff")) {
      // staff members can only view classes in their org
      const staffMember = await fetchStaffMemberByCognitoId(cognitoId, () => { })
      if (staffMember.orgId !== orgId) {
        throw new Error("staff member does not have permissions to view this organisation")
      }
    } else {
      throw new Error("User Role not found")
    }
    // fetch org
    if (!orgId) throw new Error("orgId is undefined")
    fetchOrgById(orgId, setOrg)
  }, [
    orgId,
    getCognitoId,
    getUserGroups,
    fetchStaffMemberByCognitoId,
    fetchOrgById
  ]);

  useEffect(() => {
    load()
  }, [load]);

  if (!org) {
    return <Loading />
  }

  return (
    <>
      <RAProfile
        primary={org.name}
        secondary={org.primaryAddress}
      >
        <ActionsMenu>
          <ActionMenuItem testId="edit" onClick={() => setModalContent(<OrgModal mode="update" org={org} />)}>Edit</ActionMenuItem>
          <ActionMenuItem testId="delete" onClick={() => setModalContent(<OrgModal mode="delete" org={org} />)}>Delete</ActionMenuItem>
        </ActionsMenu>
        <StatTile
          title="Total Active Classes"
          value={org.numClasses}
          testId="total-active-classes-tile"
        />
        <StatTile
          title="Total Active Students"
          value={org.numStudents}
          testId="total-active-students-tile"
        />
      </RAProfile>

      <AnalyticaTableContainer>
        <OrgClasses
          title="Classes"
          icon={ICON_CLASS}
          org={org}
          reload={load}
          testId="view-classes"
        />
        <OrgStudents
          title="Students"
          icon={ICON_STUDENT}
          org={org}
          reload={load}
          testId="view-students"
        />
        <OrgStaff
          title="Staff"
          icon={ICON_STAFF}
          org={org}
          reload={load}
          testId="view-staff"
        />
      </AnalyticaTableContainer>
    </>
  )
}
