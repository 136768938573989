import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Avatar, HStack, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount } from '../Account';
import { ModalContext } from '../Modal';
import { IssueReportModal } from '../modal/issue-report';

export const AvatarMenu = () => {

  const navigate = useNavigate()
  const { setModalContent } = useContext(ModalContext)
  const { getUserAttributes, signOut } = useAccount()
  const [userFullName, setUserFullName] = useState<string>()

  useEffect(() => {
    const load = async () => {
      const userAttributes = await getUserAttributes()
      if (!userAttributes) {
        throw new Error("userAttributes is not defined")
      }
      if (!userAttributes.firstName && !userAttributes.lastName) {
        setUserFullName(userAttributes.email)
      }
      setUserFullName(`${userAttributes.firstName} ${userAttributes.lastName}`)
    }
    load()
  }, [getUserAttributes])

  const openIssueReport = () => {
    setModalContent(
      <IssueReportModal />
    )
  }

  const onSignOut = () => {
    signOut()
    navigate('/login', { replace: true })
  }

  return <Menu size="sm" placement="bottom-start">
    {({ isOpen }) => (
      <>
        <MenuButton test-id="avatar">
          <HStack>
            <Avatar size="sm" name={userFullName} src={""} />
            {isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
          </HStack>
        </MenuButton>
        <MenuList>
          <MenuItem fontSize="sm" test-id="my-profile" onClick={() => navigate("/profile")} >My Profile</MenuItem>
          <MenuItem fontSize="sm" test-id="issue-report" onClick={() => openIssueReport()} >Report an Issue</MenuItem>
          <MenuDivider />
          <MenuItem fontSize="sm" test-id="signout" onClick={() => onSignOut()}>Sign out</MenuItem>
        </MenuList>
      </>
    )}
  </Menu>
}

