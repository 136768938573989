import React from 'react';

export interface RTableColumnProps {
  id: string;
  name: string;
  format?: string;
  component?: Function;
  isClickable?: boolean
  copyOnClick?: boolean
  editable?: boolean
}

export const RTableColumn: React.FC<RTableColumnProps> = () => {
  return <></>
}
