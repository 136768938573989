import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Org, StaffMember } from "../../../data/classes";
import { fullname } from "../../../data/functions";
import { useRequests } from "../../../data/requests";
import { ICON_CLASS, ICON_STUDENT } from "../../../theme";
import { useAccount } from "../../Account";
import { Loading } from "../../loading";
import { ModalContext } from "../../Modal";
import { StaffMemberModal } from "../../modal/staff-member";
import { StatTile } from "../../stats";
import { ActionsMenu } from "../../table/action";
import { ActionMenuItem } from "../../table/action/action-menu-item";
import { AnalyticaTableTabProps } from "../../table/analytica-table";
import { AnalyticaTableContainer } from "../../table/analytica-table-container";
import { RAProfile } from "../profile";
import { StaffMemberClasses } from "./classes";
import { StaffMemberStudents } from "./students";

export interface StaffMemberTableTabProps extends AnalyticaTableTabProps {
  org: Org
  staffMember: StaffMember
}

export const StaffMemberProfile = () => {

  const { getCognitoId, getUserGroups } = useAccount()
  const { fetchOrgById, fetchStaffMemberById, fetchStaffMemberByCognitoId } = useRequests()
  const { setModalContent } = useContext(ModalContext)
  const [staffMember, setStaffMember] = useState<StaffMember | null>()
  const [org, setOrg] = useState<Org | null>()


  let { staffMemberId } = useParams();

  const load = useCallback(async () => {
    // fetch student
    if (!staffMemberId) throw new Error("staffMemberId is undefined")
    const staffMember = await fetchStaffMemberById(staffMemberId, setStaffMember)
    // fetch cognito id
    const cognitoId = await getCognitoId()
    if (!cognitoId) {
      throw new Error("CognitoId Not Found")
    }
    const userGroups = await getUserGroups()
    if (userGroups.includes("admin")) {
      // nothing here, since admins can view all staff members
    } else if (userGroups.includes("staff")) {
      // staff members can only view classes in their org
      const currentUserStaffMember = await fetchStaffMemberByCognitoId(cognitoId, () => { })
      if (currentUserStaffMember.orgId !== staffMember.orgId) {
        throw new Error("staff member does not have permissions to view this other staff member")
      }
    } else {
      throw new Error("User Role not found")
    }
    // fetch org
    fetchOrgById(staffMember.orgId, setOrg)
  }, [
    staffMemberId,
    getCognitoId,
    getUserGroups,
    fetchStaffMemberByCognitoId,
    fetchStaffMemberById,
    fetchOrgById
  ]);

  useEffect(() => {
    load()
  }, [load]);

  if (!org || !staffMember) {
    return <Loading />
  }

  return (
    <>
      <RAProfile
        primary={fullname(staffMember) || ''}
        secondary={org.name}
      >
        <ActionsMenu>
          <ActionMenuItem testId="edit" onClick={() => setModalContent(
            <StaffMemberModal mode="update" staffMember={staffMember} org={org} />
          )}>Edit</ActionMenuItem>
          <ActionMenuItem testId="delete" onClick={() => setModalContent(
            <StaffMemberModal mode="delete" staffMember={staffMember} org={org} />
          )}>Delete</ActionMenuItem>
        </ActionsMenu>
        <StatTile
          title="Total Active Classes"
          value={staffMember.numClasses}
          testId="total-active-classes-tile"
        />
        <StatTile
          title="Total Active Students"
          value={staffMember.numStudents}
          testId="total-active-students-tile"
        />
      </RAProfile>

      <AnalyticaTableContainer>
        <StaffMemberClasses
          title="Classes"
          icon={ICON_CLASS}
          org={org}
          staffMember={staffMember}
          reload={load}
          testId="view-classes"
        />
        <StaffMemberStudents
          title="Students"
          icon={ICON_STUDENT}
          org={org}
          staffMember={staffMember}
          reload={load}
          testId="view-students"
        />
      </AnalyticaTableContainer>
    </>
  )
}
