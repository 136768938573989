import { Text } from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { COLOUR_PRIMARY_GREY, MARGIN_LARGE, MARGIN_SMALL, RADIUS_PRIMARY } from '../../theme';

export interface SidebarNavItemProps extends React.PropsWithChildren {
  to: string
  testId?: string
}

export const SidebarNavItem = (props: SidebarNavItemProps) => {

  return <NavLink
    to={props.to}
    test-id={props.testId}
  >
    <Text
      w="full"
      px={MARGIN_LARGE}
      py={MARGIN_SMALL}
      borderRadius={RADIUS_PRIMARY}
      fontSize="sm"
      _hover={{ backgroundColor: COLOUR_PRIMARY_GREY }}
    >{props.children}</Text>
  </NavLink>
}
