import { Box, Center, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { validateNonEmpty } from "../data/validations";
import { useAccount } from "./Account";
import { LoginButton } from "./button";
import { AuthForm } from './form';
import { EmailInput, PasswordInput } from "./form/input";

interface LoginInfo {
  email: string;
  password: string;
}

export const Login = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const [error, setError] = useState<string | null>(null)
  const { signIn, getToken, getUserGroups } = useAccount()

  const navigateToForgotPassword = () => {
    navigate("/forgot-password", { replace: true });
  }

  const navigateToHome = useCallback(async () => {
    const userGroups = await getUserGroups()
    if (userGroups.includes("admin")) {
      return navigate('/orgs')
    } else if (userGroups.includes("staff")) {
      return navigate('/classes')
    } else {
      throw new Error("User Role not found")
    }
  }, [getUserGroups, navigate])

  const onSubmit = async (values: LoginInfo, callback: () => void) => {
    console.log("Submit!")
    try {
      await signIn(values.email, values.password);
      await navigateToHome()
    } catch (err: any) {
      if (err.type === "NEW_PASSWORD_REQUIRED") {
        navigate("/reset-password", { replace: true });
      } else {
        setError(err.name === "NotAuthorizedException" ? "Incorrect username or password." : "Error authenticating account")
      }
    }
    callback()
  };

  useEffect(() => {
    const checkAuthorization = async () => {
      const token = await getToken();
      if (token) {
        navigateToHome()
      }
    };
    checkAuthorization();
  }, [getToken, navigateToHome]);

  return (
    <AuthForm
      name="login"
      title="Welcome!"
      error={error}
      onSubmit={onSubmit}
      initialValues={{ email: "", password: "" }}
    >
      {(location.state && location.state.msg) &&
        <>
          <Center pt={5}>
            <Text w="70%" textAlign="center">{location.state.msg}</Text>
          </Center>
          <Box pt={5} />
        </>
      }
      <EmailInput testId="email" validation={validateNonEmpty} />
      <PasswordInput testId="password" validation={validateNonEmpty} />
      <Box pt={4} />
      <LoginButton w="full" clickOnEnter testId="log-in-button" />
      <Box pt={4} />
      <Text
        fontSize="sm"
        onClick={navigateToForgotPassword}
        textDecoration="none"
        _hover={{
          textDecoration: "underline",
          cursor: "pointer",
        }}
        test-id="go-to-forgot-password-button"
      >Forgot Password?</Text>
    </AuthForm>
  )
}
