import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Org, StaffMember } from '../../../data/classes';
import { fullname } from '../../../data/functions';
import { useRequests } from '../../../data/requests';
import { validateEmail, validateName } from '../../../data/validations';
import { AnalyticaForm } from '../../form';
import { TextInput } from '../../form/input';
import { ModalMode } from '../../Modal';

export interface StaffMemberModalProps {
  org: Org
  staffMember?: StaffMember
  mode: ModalMode
  callback?: () => void
}

export const StaffMemberModal: React.FC<StaffMemberModalProps> = ({ ...props }) => {

  const navigate = useNavigate()

  const deleteAndReturn = async (staffMember: StaffMember, callback: () => void) => {
    deleteStaffMember(staffMember, callback)
    navigate(-1)
  }

  const { deleteStaffMember, postStaffMember, putStaffMember } = useRequests()

  let title = "Modal Title Placeholder"
  let description = "Modal description Placeholder"
  let initialValues

  switch (props.mode) {
    case "add":
      title = "Invite a Staff Member"
      description = `Invite a Staff Member to join ${props.org.name}. The new user will receive an email with their login details.`
      initialValues = { orgId: props.org.id }
      break
    case "update":
      if (!props.staffMember) {
        throw new Error("Item not supplied");
      }
      title = "Edit Staff Member"
      description = "Update Staff Member Details."
      initialValues = props.staffMember
      break
    case "delete":
      if (!props.staffMember) {
        throw new Error("Item not supplied");
      }
      title = "Delete Staff Member"
      description = `Are you sure you want to delete Staff Member - ${fullname(props.staffMember)}?`
      initialValues = { id: props.staffMember.id }
      break
  }

  return (
    <AnalyticaForm
      name='staff-member-modal'
      title={title}
      description={description}
      initialValues={initialValues}
      mode={props.mode}
      onSubmit={
        props.mode === 'update' ? putStaffMember
          : props.mode === 'delete' ? deleteAndReturn
            : postStaffMember}
      callback={props.callback}
    >
      {
        props.mode !== 'delete' &&
        <AnalyticaForm.SingleItemForm>
          <TextInput
            name="email"
            title="Email Address"
            testId='staff-member-email-input'
            validation={validateEmail}
          />
          <TextInput
            name="preferredName"
            title="Preferred Name"
            testId='staff-member-preferred-name-input'
            validation={validateName}
            optional
          />
          <TextInput
            name="lastName"
            title="Last Name"
            testId='staff-member-last-name-input'
            validation={validateName}
            optional
          />
        </AnalyticaForm.SingleItemForm>
      }
    </AnalyticaForm>
  )
}
