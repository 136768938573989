import { Tag } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { PageParams } from "../../../../data/api";
import { PaginatedData, Test } from "../../../../data/classes";
import { datetimeTitle } from "../../../../data/functions";
import { useRequests } from "../../../../data/requests";
import { BORDER_DEFAULT, ICON_STAFF } from "../../../../theme";
import { AnalyticaTable } from "../../../table";
import { RTableColumn } from "../../../table/column";
import { RTableColumnProps } from "../../../table/column/r-table-column";
import { StudentTableTabProps } from "../student-profile";

export const StudentTests: React.FC<StudentTableTabProps> = ({ ...props }) => {

  const { fetchTestsByStudentId } = useRequests()

  const fetchTests = (setItems: (p: PaginatedData<Test>) => void, pageParams: PageParams) => {
    fetchTestsByStudentId(props.student.id, setItems, pageParams);
  }

  const testTitleComponent = (_column: ReactElement<RTableColumnProps>, t: Test) => {
    return datetimeTitle(t)
  }

  const testStatus = (t: Test) =>
    <Tag
      border={BORDER_DEFAULT}
      bg={t.completed ? "#F0FFF4" : "#FFF5EE"}
    >{t.completed ? "COMPLETED" : "PENDING"}</Tag>

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchTests}
        searchable
        searchOnChange
        to={"/tests"}
      >
        <RTableColumn id="created" name="Created" component={testTitleComponent} />
        <RTableColumn id="status" name="Status" component={testStatus} />
        <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
        <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
      </AnalyticaTable>
    </>
  )
}
