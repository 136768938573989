import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PageParams } from '../../../data/api';
import { Class, Org, PaginatedData, StaffMember } from '../../../data/classes';
import { useRequests } from '../../../data/requests';
import { ICON_CLASS } from '../../../theme';
import { useAccount } from '../../Account';
import { Loading } from '../../loading';
import { ModalContext } from '../../Modal';
import { ClassModal } from '../../modal/class';
import { AnalyticaTable } from '../../table';
import { ActionsMenu } from '../../table/action';
import { ActionMenuItem } from '../../table/action';
import { RTableColumn } from '../../table/column';

export const Classes = () => {

  const { getCognitoId } = useAccount()
  const { setModalContent } = useContext(ModalContext)
  const { fetchClassesByStaffMemberId, fetchOrgById, fetchStaffMemberByCognitoId } = useRequests()

  const [org, setOrg] = useState<Org | null>()
  const [staffMember, setStaffMember] = useState<StaffMember | null>(null)

  const load = useCallback(async () => {
    const cognitoId = await getCognitoId()
    if (!cognitoId) {
      throw new Error("CognitoId Not Found")
    }
    const staffMember = await fetchStaffMemberByCognitoId(cognitoId, setStaffMember)
    fetchOrgById(staffMember.orgId, setOrg)
  }, [getCognitoId, fetchStaffMemberByCognitoId, fetchOrgById]);

  useEffect(() => {
    load()
  }, [load]);

  if (!org || !staffMember) {
    return <Loading />
  }

  const fetchClasses = (setItems: (p: PaginatedData<Class>) => void, pageParams: PageParams) =>
    fetchClassesByStaffMemberId(staffMember.id, setItems, pageParams);

  return (
    <AnalyticaTable
      title='Classes'
      icon={ICON_CLASS}
      fetchItems={fetchClasses}
      defaultLimit={20}
      searchable
      searchOnChange
      fullscreen
    >
      <ActionsMenu>
        <ActionMenuItem
          onClick={() => setModalContent(
            <ClassModal
              mode="add"
              org={org}
              staffMember={staffMember}
              callback={load}
            />
          )}
          testId="new-class-button"
        >New Class</ActionMenuItem>
      </ActionsMenu>

      <RTableColumn id="name" name="Name" />
      <RTableColumn id="yearLevel" name="Year Level" />
      <RTableColumn id="numStudents" name="#Students" />
      <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
      <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />

    </AnalyticaTable>
  )
}
