import { useModalContext } from "@chakra-ui/react"
import React from "react"
import { AnalyticaButtonProps, ModalButton } from "./AnalyticaButton"

interface Props extends AnalyticaButtonProps { }

export const CancelButton = (props: Props) => {

  const { onClose } = useModalContext()

  return (
    <ModalButton
      background="white"
      textColor="black"
      onClick={onClose}
      {...props}
    >Cancel</ModalButton>
  )
}
