import { Box, Center, Text, HStack, Heading, VStack, Input } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import React, { PropsWithChildren, useRef } from "react";
import { BORDER_PRIMARY_DARK, COLOUR_PRIMARY_LIGHT, COLOUR_PRIMARY_WHITE, RADIUS_PRIMARY } from "../../theme";
import { LoginButton, ModalButton } from "../button";
import { TextInput, EmailInput, PasswordInput } from "./input";

export interface AuthFormProps extends PropsWithChildren {
  name: string
  title: string
  initialValues?: object
  onSubmit: (a: any, cb: () => void) => void,
  error: string | null
}

export const AuthForm = (props: AuthFormProps) => {

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      buttonRef.current?.focus();
      buttonRef.current?.click();
    }
  };

  // Function to recursively add onKeyDown, or buttonRef to necessary components
  const enhanceChildren = (children: React.ReactNode, isSubmitting: boolean): React.ReactNode =>
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {

        const isTextInputComponent =
          child.type === Input ||
          child.type === TextInput ||
          child.type === EmailInput ||
          child.type === PasswordInput;

        if (isTextInputComponent) {
          return React.cloneElement(child, {
            ...child.props,
            onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
              handleKeyDown(event);
            }
          } as typeof child.props);
        }

        const isButtonComponent =
          child.type === LoginButton ||
          child.type === ModalButton;

        if (isButtonComponent) {
          return React.cloneElement(child, {
            ...child.props,
            ref: buttonRef,
            isLoading: child.type === LoginButton ? isSubmitting : child.props.isLoading,
          } as typeof child.props);
        }
      }
      return child;
    });

  return (
    <>
      <Center
        w='calc(100vw)'
        h='calc(100vh)'
        test-id="login-form"
        background={COLOUR_PRIMARY_LIGHT}
      >
        <Box
          w='22%'
          h='auto'
          borderRadius={RADIUS_PRIMARY}
          border={BORDER_PRIMARY_DARK}
          background={COLOUR_PRIMARY_WHITE}
          padding={5}
        >
          <Heading w={40} size="lg">Rubric Analytica</Heading>

          <Box pt={5} />
          <Box pt={10} />
          <Center>
            <Text fontSize="lg">{props.title}</Text>
          </Center>
          <Box pt={10} />

          <Formik
            initialValues={props.initialValues || {}}
            validateOnMount
            enableReinitialize
            onSubmit={(values, actions) => {
              setTimeout(() => {
                props.onSubmit(values, () => {
                  actions.setSubmitting(false);
                  actions.resetForm();
                });
              }, 1000);
            }}
          >
            {({ isSubmitting }) => {
              return <Form>
                <HStack spacing="100px" align="stretch">
                  <VStack w="full">
                    {enhanceChildren(props.children, isSubmitting)}
                  </VStack>
                </HStack>
                {
                  props.error &&
                  <Center>
                    <Text test-id="error-message" textColor="red" > {props.error} </Text>
                  </Center>
                }
              </Form>
            }}
          </Formik>
        </Box >
      </Center>
    </>
  )
}
