import React from 'react'
import { PasswordInput, PasswordInputProps } from './PasswordInput'
import { validateConfirmPassword } from '../../../data/validations'
import { useFormikContext } from 'formik'

export const ConfirmPasswordInput = (props: PasswordInputProps) => {

  const { values } = useFormikContext<{ password: string }>()

  return <PasswordInput
    type={props.type || ConfirmPasswordInput.defaultProps.type}
    name={props.name || ConfirmPasswordInput.defaultProps.name}
    validation={props.validation || validateConfirmPassword(values.password)}
    {...props}
  />
}

ConfirmPasswordInput.defaultProps = {
  type: "password",
  name: "confirm-password",
};
