import React from "react";
import { PageParams } from "../../../../data/api";
import { Class, PaginatedData } from "../../../../data/classes";
import { useRequests } from "../../../../data/requests";
import { ICON_CLASS } from "../../../../theme";
import { AnalyticaTable } from "../../../table";
import { RTableColumn } from "../../../table/column";
import { StudentTableTabProps } from "../student-profile";

export const StudentClasses: React.FC<StudentTableTabProps> = ({ ...props }) => {

  const { fetchClassesByStudentId } = useRequests()

  const fetchClasses = (setItems: (p: PaginatedData<Class>) => void, pageParams: PageParams) =>
    fetchClassesByStudentId(props.student.id, setItems, pageParams);

  return (
    <>
      <AnalyticaTable
        icon={ICON_CLASS}
        fetchItems={fetchClasses}
        searchable
        searchOnChange
        sort="name"
        to={"/classes"}
      >
        <RTableColumn id="name" name="Name" />
        <RTableColumn id="yearLevel" name="Year Level" />
        <RTableColumn id="numStudents" name="#Students" />
        <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
        <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />

      </AnalyticaTable>
    </>
  )
}
