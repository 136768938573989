import React, { ReactElement, useContext } from "react";
import { PageParams } from "../../../../data/api";
import { PaginatedData, StaffMember } from "../../../../data/classes";
import { fullname } from '../../../../data/functions';
import { useRequests } from "../../../../data/requests";
import { ICON_STAFF } from "../../../../theme";
import { ModalContext } from "../../../Modal";
import { StaffMemberModal } from "../../../modal/staff-member";
import { AnalyticaTable } from "../../../table";
import { ActionsMenu } from "../../../table/action";
import { ActionMenuItem } from "../../../table/action/action-menu-item";
import { RTableColumn } from "../../../table/column";
import { RTableColumnProps } from "../../../table/column/r-table-column";
import { OrgTableTabProps } from "../org-profile";

export const OrgStaff: React.FC<OrgTableTabProps> = ({ ...props }) => {

  const { setModalContent } = useContext(ModalContext)
  const { fetchStaffByOrgId } = useRequests()

  const fetchStaff = (setItems: (p: PaginatedData<StaffMember>) => void, pageParams: PageParams) => {
    fetchStaffByOrgId(props.org.id, setItems, pageParams);
  }

  const fullnameComponent = (_column: ReactElement<RTableColumnProps>, s: StaffMember) => {
    return fullname(s)
  }

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchStaff}
        searchable
        searchOnChange
        sort="preferredName"
        to={"/staff"}
      >
        <ActionsMenu>
          <ActionMenuItem
            testId='new-staff-member-button'
            onClick={() => setModalContent(
              <StaffMemberModal
                mode="add"
                org={props.org}
                callback={props.reload}
              />
            )}
          >New Staff Member</ActionMenuItem>
        </ActionsMenu>

        <RTableColumn id="name" name="Name" component={fullnameComponent} />
        <RTableColumn id="email" name="Email Address" />
        <RTableColumn id="numClasses" name="#Classes" />
        <RTableColumn id="numStudents" name="#Students" />

      </AnalyticaTable>
    </>
  )
}
