import { Center, Text } from "@chakra-ui/react";
import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { validateNonEmpty } from "../data/validations";
import { useAccount } from "./Account";
import { AuthForm } from "./form";
import { TextInput } from "./form/input";

export const Verify = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null)

  const { confirmRegistration } = useAccount()

  const onSubmit = async (values: any) => {
    try {
      await confirmRegistration(location.state.email, values.verificationCode);
      navigate("/login", { replace: true, state: { msg: "Success! Please Login." } });
    } catch (err: any) {
      setError(err.name === "CodeMismatchException" ? "Invalid Code" : "Error verifying account")
    }
  }

  return (
    <AuthForm
      name="verify"
      title="Verify your Account"
      error={error}
      onSubmit={onSubmit}
    >
      <Center>
        <Text align="center" opacity="80%" size="sm">You should now receive an email with a verification code.</Text>
      </Center>

      <TextInput
        name="verificationCode"
        title="Verification Code"
        testId="verification-code-input"
        validation={validateNonEmpty}
      />
    </AuthForm>
  )
}

export default Verify
