import { Avatar, Box, Heading, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { BORDER_DEFAULT, COLOUR_PRIMARY_WHITE, MARGIN_LARGE, RADIUS_PRIMARY } from "../../theme";
import { StatTile } from "../stats";
import { ActionsMenu } from "../table/action";

export interface ProfileProps {
  children?: ReactElement | ReactElement[]
  primary: string
  secondary: string
}

export const RAProfile = ({ ...props }: ProfileProps) => {

  const actions = React.Children.toArray(props.children).filter(
    (child) => React.isValidElement(child) && child.type === ActionsMenu
  );

  const stats = React.Children.toArray(props.children).filter(
    (child) => React.isValidElement(child) && child.type === StatTile
  );

  return (
    <Box
      test-id="profile"
      p={MARGIN_LARGE}
      mb={MARGIN_LARGE}
      background={COLOUR_PRIMARY_WHITE}
      borderRadius={RADIUS_PRIMARY}
      border={BORDER_DEFAULT}
      w="full"
    >
      <HStack>
        <Avatar
          name={props.primary}
          src={""}
          size="md"
        />
        <VStack pl={MARGIN_LARGE} align="flex-start">
          <Heading fontSize="md">{props.primary}</Heading>
          <Text fontSize="sm">{props.secondary}</Text>
        </VStack>
        <Spacer />
        {actions && actions}
      </HStack>
      <HStack pt={10}>
        {stats}
      </HStack>
    </Box>
  )
}
