import axios from "axios";

export const createAxiosInstance = (token: string) => {

  const axiosInstance = axios.create();

  // Request interceptor to attach the token
  axiosInstance.interceptors.request.use(
    async (config) => {
      try {
        config.headers['Authorization'] = `Bearer ${token}`;
      } catch (error) {
        console.error(error)
      }
      return config;
    });

  return axiosInstance;
};
