import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Center, Flex, HStack, IconButton, Spacer, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import React, { forwardRef, ReactElement, ReactNode, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { PageParams } from "../../data/api";
import { PaginatedData } from "../../data/classes";
import { BORDER_DEFAULT, COLOUR_PRIMARY_DARK, COLOUR_PRIMARY_GREY, COLOUR_PRIMARY_LIGHT, MARGIN_LARGE } from "../../theme";
import { Loading } from "../loading";
import { RAToast } from "../toast/toast";
import { RTableColumn, RTableColumnProps } from "./column/r-table-column";

const R = require('ramda');

export type AnalyticaTableBodyRef = {
  reload: () => void;
  search: (text: string) => void;
};

export interface AnalyticaTableBodyProps extends React.PropsWithChildren {
  fetchItems: Function
  icon?: ReactNode
  render?: boolean
  searchable?: boolean
  searchOnChange?: boolean
  sort?: string
  order?: string
  defaultLimit?: number
  hidePagination?: boolean
  fullscreen?: boolean
  itemTestId?: Function
  to?: string
  scrollable?: boolean
  editable?: boolean
}

export const AnalyticaTableBody = forwardRef<AnalyticaTableBodyRef, AnalyticaTableBodyProps>((
  {
    searchable = false,
    searchOnChange = false,
    hidePagination = false,
    fullscreen = false,
    scrollable = false,
    editable = false,
    fetchItems,
    order,
    sort,
    defaultLimit,
    ...props
  }, ref) => {

  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(defaultLimit ?? 10);
  const [pageData, setPageData] = useState<PaginatedData<any>>();
  const [search, setSearch] = useState<string>("");
  const [reloadKey, setReloadKey] = useState(0);

  // Expose functions
  useImperativeHandle(ref, () => ({
    reload: () => setReloadKey((prev) => prev + 1),
    search: (text: string) => setSearch(text),
  }), []);

  const navigate = useNavigate()

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      RAToast({
        title: 'Copied to clipboard!',
        description: `You copied: "${text}"`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      RAToast({
        title: 'Failed to copy',
        description: `Could not copy "${text}" to clipboard`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const start = useCallback(() => {
    return (page - 1) * limit
  }, [page, limit])

  const loadItems = useCallback(() => {
    const loadData = async () => {
      await fetchItems(setPageData, new PageParams(start(), limit, sort || "", order || "", search))
    };
    loadData()
  }, [setPageData, start, limit, order, sort, search, fetchItems])

  useEffect(() => {
    loadItems()
  }, [loadItems, reloadKey, search]);

  if (!pageData) {
    return (
      <Loading />
    )
  }

  let isEmpty = pageData && R.isEmpty(pageData.data)

  let columns = React.Children.toArray(props.children).filter(
    (child) => React.isValidElement(child) && child.type === RTableColumn
  );

  if (!columns) {
    console.error("No Columns Supplied to table!")
    return (
      <Loading />
    )
  }

  // ensure colums is always an array
  columns = Array.isArray(columns) ? columns : [columns];

  let tableHeaderRow = R.map(
    (column: ReactElement<RTableColumnProps>) =>
      <Th
        key={`column-name-${column.props.name}`}
        px={5}
      >{column.props.name}</Th >,
    columns)

  const navigateToProfile = (item: any) => {
    navigate(
      props.to ? (props.to + "/" + item.id) : "./" + item.id
    );
  }

  const onClickCell = (column: ReactElement<RTableColumnProps> | null, item: any) => {
    if (column === null) {
      return
    }
    if (column?.props?.copyOnClick) {
      handleCopy(field(column, item))
      return
    }
    if (column?.props.isClickable === false) {
      return
    }
    navigateToProfile(item);
  }

  const value = (column: ReactElement<RTableColumnProps>, value: any) => {
    if (value === undefined || value === '') {
      return "-"
    }
    if (typeof value === 'boolean') {
      return value
    }
    if (value === "true") {
      return true
    }
    if (value === "false") {
      return false
    }
    if (isNaN(value)) {
      return value
    }
    if (column.props.format === "percentage") {
      return `${Number(value * 100).toFixed(1)}%`
    }
    if (Number.isInteger(value)) {
      return value
    }
    return Number(value).toFixed(2)
  }

  const field = (column: ReactElement<RTableColumnProps>, item: any) => {
    if (column.props.component) {
      let v = column.props.component(column, item, pageData.data, setPageData)
      return value(column, v)
    }
    if (!column.props.id) {
      throw new Error("column is missing an id")
    }
    let v = item[column.props.id]
    return value(column, v)
  }

  let tableRows = R.map((index: number) => {
    if (pageData.data.length <= index) {
      return <Tr className="tableRow" >
        {
          R.map(() => {
            return <Td h={8} />
          }, columns)
        }
      </Tr>
    }
    const item = pageData.data[index]
    return <Tr
      className="tableRow"
      key={item.id}
      test-id={item.id}
      borderBottom={BORDER_DEFAULT}
      _hover={{
        backgroundColor: COLOUR_PRIMARY_LIGHT,
        cursor: 'pointer',
      }}
    >
      {
        R.map((column: ReactElement<RTableColumnProps>) => {
          return <Td
            h={8}
            key={`${item.id}-${column.props.id}`}
            px={5}
            test-id={column.props.id}
            onClick={() => onClickCell(column, item)}
          >
            {field(column, item)}
          </Td>
        }, columns)
      }
    </Tr >
  }, R.range(0, limit))

  let table =
    <TableContainer test-id="items-list">
      <Table size="sm" variant="unstyled">
        <Thead key="table-head">
          <Tr key="table-header-row" borderBottom={BORDER_DEFAULT} >
            {tableHeaderRow}
          </Tr>
        </Thead>
        <Tbody>
          {tableRows}
        </Tbody>
      </Table>
    </TableContainer>

  const pageLeft = () => setPage(page - 1)
  const pageRight = () => setPage(page + 1)

  let numberOfResults = <HStack>
    <Text fontSize="xs" >Showing Results: </Text>
    <Text fontSize="xs" fontWeight="bold" >{`${start() + 1}-${start() + pageData.data.length}`}</Text>
    <Text fontSize="xs" > of </Text>
    <Text fontSize="xs" fontWeight="bold" >{pageData.total}</Text>
  </HStack>

  let pagination =
    <Box>
      <Flex>
        <Spacer />
        <HStack h={10}>
          <IconButton
            size="sm"
            icon={<ChevronLeftIcon />}
            onClick={pageLeft}
            isDisabled={page === 1}
            bg="transparent"
            color={COLOUR_PRIMARY_DARK}
            _hover={{ backgroundColor: COLOUR_PRIMARY_GREY }}
            aria-label={""} />
          <Text fontSize="xs" >Page </Text>
          <Text fontSize="xs" fontWeight="bold">{page}</Text>
          <IconButton
            size="sm"
            icon={<ChevronRightIcon />}
            onClick={pageRight}
            isDisabled={page >= Math.ceil(pageData.total / limit)}
            bg="transparent"
            color={COLOUR_PRIMARY_DARK}
            _hover={{ backgroundColor: COLOUR_PRIMARY_GREY }}
            aria-label={""} />
        </HStack>
      </Flex>
    </Box>

  let noResults =
    <Center>
      <Text m={10} fontSize="xs">No Results Found...</Text>
    </Center>

  return (
    <div key={reloadKey}>
      <Box >
        <Box pt={MARGIN_LARGE} overflowY={scrollable ? "auto" : "hidden"}>
          {isEmpty ? noResults : table}
        </Box>
        <Box>
          {!hidePagination &&
            <HStack
              px={MARGIN_LARGE}
            >
              {numberOfResults}
              <Spacer />
              {pagination}
            </HStack>
          }
        </Box>
      </Box>
    </div>
  )
})
