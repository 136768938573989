import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Org, Class } from "../../../data/classes";
import { useRequests } from "../../../data/requests";
import { ICON_STAFF, ICON_STUDENT, ICON_TEST } from "../../../theme";
import { useAccount } from "../../Account";
import { Loading } from "../../loading";
import { ModalContext } from "../../Modal";
import { ClassModal } from "../../modal/class";
import { StatTile } from "../../stats";
import { ActionsMenu } from "../../table/action";
import { ActionMenuItem } from "../../table/action/action-menu-item";
import { AnalyticaTableTabProps } from "../../table/analytica-table";
import { AnalyticaTableContainer } from "../../table/analytica-table-container";
import { RAProfile } from "../profile";
import { ClassStaff } from "./staff";
import { ClassStudents } from "./students";
import { ClassTests } from "./tests";

export interface ClassTableTabProps extends AnalyticaTableTabProps {
  org: Org
  clazz: Class
  callback?: () => void
}

export const ClassProfile = () => {

  const { getCognitoId, getUserGroups } = useAccount()
  const { fetchOrgById, fetchClassById, fetchStaffMemberByCognitoId } = useRequests()
  const { setModalContent } = useContext(ModalContext)
  const [clazz, setClass] = useState<Class | null>()
  const [org, setOrg] = useState<Org | null>()


  let { classId } = useParams();

  const load = useCallback(async () => {
    // fetch class
    if (!classId) throw new Error("classId is undefined")
    const clazz = await fetchClassById(classId, setClass)
    // fetch cognito id
    const cognitoId = await getCognitoId()
    if (!cognitoId) {
      throw new Error("CognitoId Not Found")
    }
    const userGroups = await getUserGroups()
    if (userGroups.includes("admin")) {
      // nothing here, since admins can view all classes
    } else if (userGroups.includes("staff")) {
      // staff members can only view classes in their org
      const staffMember = await fetchStaffMemberByCognitoId(cognitoId, () => { })
      if (staffMember.orgId !== clazz.orgId) {
        throw new Error("staff member does not have permissions to view this class")
      }
    } else {
      throw new Error("User Role not found")
    }
    // fetch org
    fetchOrgById(clazz.orgId, setOrg)
  }, [
    classId,
    getCognitoId,
    getUserGroups,
    fetchStaffMemberByCognitoId,
    fetchOrgById,
    fetchClassById
  ]);

  useEffect(() => {
    load()
  }, [load]);

  if (!org || !clazz) {
    return <Loading />
  }

  return (
    <>
      <RAProfile
        primary={clazz.name}
        secondary={org.name}
      >
        <ActionsMenu>
          <ActionMenuItem testId="edit" onClick={() => setModalContent(<ClassModal mode="update" clazz={clazz} org={org} />)}>Edit</ActionMenuItem>
          <ActionMenuItem testId="delete" onClick={() => setModalContent(<ClassModal mode="delete" clazz={clazz} org={org} />)}>Delete</ActionMenuItem>
        </ActionsMenu>
        <StatTile
          title="Total Active Students"
          value={clazz.numStudents}
          testId="total-active-students-tile"
        />
      </RAProfile>

      <AnalyticaTableContainer>
        <ClassStudents
          title="Students"
          icon={ICON_STUDENT}
          org={org}
          clazz={clazz}
          reload={load}
          testId="view-students"
        />
        <ClassStaff
          title="Staff"
          icon={ICON_STAFF}
          org={org}
          clazz={clazz}
          reload={load}
          testId="view-staff"
        />
        <ClassTests
          title="Tests"
          icon={ICON_TEST}
          org={org}
          clazz={clazz}
          reload={load}
          testId="view-tests"
        />
        {/* <ClassFeedbackReportBatches title="Feedback Report Batches" icon={ICON_REPORT} org={org} clazz={clazz} testId="view-feedback-report-batches" /> */}
      </AnalyticaTableContainer>
    </>
  )
}
