import { Box } from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Admin, StaffMember, User } from "../../data/classes";
import { useRequests } from "../../data/requests";
import { validateName } from "../../data/validations";
import { useAccount } from "../Account";
import { AnalyticaForm } from "../form";
import { TextInput } from "../form/input";
import { ModalContext } from "../Modal";
import { ActionsMenu } from "../table/action";
import { ActionMenuItem } from "../table/action/action-menu-item";
import { RAProfile } from "./profile";

export const UserProfile = () => {

  const { getCognitoId, getUserGroups, getUserAttributes, updateUserAttributes } = useAccount()
  const { fetchAdminByCognitoId, fetchStaffMemberByCognitoId, putAdmin, putStaffMember } = useRequests()
  const { setModalContent } = useContext(ModalContext)

  const [userData, setUserData] = useState<User | null>(null)
  const [userFullName, setUserFullName] = useState<string>()
  const [userEmail, setUserEmail] = useState<string>()

  const load = useCallback(async () => {
    const cognitoId = await getCognitoId()
    if (!cognitoId) {
      throw new Error("CognitoId Not Found")
    }
    const userGroups = await getUserGroups()
    if (userGroups.includes("admin")) {
      fetchAdminByCognitoId(cognitoId, setUserData)
    } else if (userGroups.includes("staff")) {
      fetchStaffMemberByCognitoId(cognitoId, setUserData)
    } else {
      throw new Error("User Role not found")
    }
    const userAttributes = await getUserAttributes()
    if (!userAttributes) {
      throw new Error("userAttributes is not defined")
    }
    setUserFullName(`${userAttributes.firstName} ${userAttributes.lastName}`)
    setUserEmail(userAttributes.email)
  }, [getCognitoId, getUserGroups, getUserAttributes, fetchAdminByCognitoId, fetchStaffMemberByCognitoId])

  useEffect(() => {
    load()
  }, [load]);

  const updateUserProfile = async (values: any, callback: Function) => {
    const updateInCognito = async () => {
      // update in cognito
      try {
        await updateUserAttributes(values.preferredName, values.lastName)
        load()
      } catch (err: any) {
        console.error(err)
      }
      callback()
    }
    // update in db
    const userGroups = await getUserGroups()
    if (userGroups.includes("admin")) {
      putAdmin(values as Admin, updateInCognito)
    } else if (userGroups.includes("staff")) {
      putStaffMember(values as StaffMember, updateInCognito)
    } else {
      throw new Error("User Role not found")
    }
  }

  const form = <AnalyticaForm
    name="update-profile"
    title="Update Profile"
    description='Update your User Profile.'
    initialValues={userData || {}}
    onSubmit={updateUserProfile}
  >
    <AnalyticaForm.SingleItemForm>
      <TextInput
        name="preferredName"
        title="Preferred Name"
        testId="user-preferred-name-input"
        validation={validateName}
      />
      <Box h={5} />
      <TextInput
        name="lastName"
        title="Last Name"
        testId="user-last-name-input"
        validation={validateName}
      />
    </AnalyticaForm.SingleItemForm>
  </AnalyticaForm>

  return (
    <RAProfile
      primary={userFullName ?? ''}
      secondary={userEmail ?? ''}
    >
      <ActionsMenu>
        <ActionMenuItem testId="edit" onClick={() => setModalContent(form)}>Edit</ActionMenuItem>
      </ActionsMenu>
    </RAProfile>
  )
}
