import { MenuItem } from '@chakra-ui/react';
import React, { MouseEventHandler, PropsWithChildren } from 'react';

export interface ActionsMenuItemProps extends PropsWithChildren {
  testId?: string
  onClick?: MouseEventHandler<HTMLElement>
}

export const ActionMenuItem = ({ ...props }: ActionsMenuItemProps) => {

  return <MenuItem
    fontSize="sm"
    test-id={props.testId}
    onClick={props.onClick}
  >
    {props.children}
  </MenuItem>
}
