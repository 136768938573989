import React, { ReactElement, useContext } from "react";
import { PageParams } from "../../../../data/api";
import { PaginatedData, StaffMember } from "../../../../data/classes";
import { fullname } from '../../../../data/functions';
import { useRequests } from "../../../../data/requests";
import { ICON_STAFF } from "../../../../theme";
import { ModalContext } from "../../../Modal";
import { ClassModal } from "../../../modal/class";
import { AnalyticaTable } from "../../../table";
import { ActionsMenu } from "../../../table/action";
import { ActionMenuItem } from "../../../table/action/action-menu-item";
import { RTableColumn } from "../../../table/column";
import { RTableColumnProps } from "../../../table/column/r-table-column";
import { ClassTableTabProps } from "../class-profile";

export const ClassStaff: React.FC<ClassTableTabProps> = ({ ...props }) => {

  const { setModalContent } = useContext(ModalContext)
  const {
    fetchStaffByClassId,
  } = useRequests()

  const fetchStaff = (setItems: (p: PaginatedData<StaffMember>) => void, pageParams: PageParams) => {
    fetchStaffByClassId(props.clazz.id, setItems, pageParams);
  }

  const fullnameComponent = (_column: ReactElement<RTableColumnProps>, s: StaffMember) => {
    return fullname(s)
  }

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchStaff}
        searchable
        searchOnChange
        sort="preferredName"
        to={"/staff"}
      >
        <ActionsMenu>
          <ActionMenuItem
            testId="add-existing-staff-button"
            onClick={() => setModalContent(
              <ClassModal
                mode="add-existing-staff"
                org={props.org}
                clazz={props.clazz}
                callback={props.reload}
              />
            )}
          >Add Existing Staff</ActionMenuItem>
        </ActionsMenu>

        <RTableColumn id="name" name="Name" component={fullnameComponent} />
        <RTableColumn id="email" name="Email Address" />
        <RTableColumn id="numClasses" name="#Classes" />
        <RTableColumn id="numStudents" name="#Students" />

      </AnalyticaTable>

    </>
  )
}
