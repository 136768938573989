import { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import { useAccount } from '../components/Account';
import { createAxiosInstance } from './axios';
import { PaginatedData } from './classes';

declare const window: any;

const SERVER_API_URI = process.env.NODE_ENV === 'production' ? window.env.SERVER_API_URI
  : process.env.REACT_APP_SERVER_API_URI || "http://127.0.0.1:8080/api"

export class PageParams {
  start: number;
  limit: number;
  sort: string;
  order: string;
  search: string;

  constructor(
    start: number,
    limit: number,
    sort: string,
    order: string,
    search: string,
  ) {
    this.start = start
    this.limit = limit
    this.sort = sort
    this.order = order
    this.search = search
  }

  toString(): string {
    const params = new URLSearchParams({
      start: this.start.toString(),
      limit: this.limit.toString(),
      sort: this.sort.toString(),
      search: this.search.toString(),
      order: this.order.toString()
    })
    // Remove keys with empty string values
    var keysToRemove: string[] = []
    params.forEach((value, key) => {
      if (!value || value.trim() === '') {
        keysToRemove.push(key)
      }
    });
    keysToRemove.forEach((key) => {
      params.delete(key);
    })
    if (params.toString() === "") {
      return ""
    }
    return "?" + params.toString()
  }
};

export const useApi = () => {

  const { getToken } = useAccount();

  const headers = (token: string) => {
    return { 'Authorization': `Bearer ${token}`, }
  }

  const config = (data?: any) => {
    if (data) {
      return { data: data }
    }
  }

  const toServerPath = (path: string) => {
    return `${SERVER_API_URI}/${path}`
  }

  const fetchItem = useCallback(async (
    path: string,
    callback?: (a: any) => void
  ): Promise<any> => {
    try {
      const token = await getToken();
      if (!token) {
        throw new Error("User is not Authenticated")
      }
      const axiosInstance = createAxiosInstance(token)
      const response = await axiosInstance.get(toServerPath(path));
      if (callback) {
        callback(response.data); // Call the callback with the fetched data
      }
      return response.data; // Return the fetched data
    } catch (error) {
      console.error('Error fetching item:', error);
      throw error; // Optionally rethrow the error to handle it upstream
    }
  }, [getToken])

  const fetchData = useCallback(async (
    path: string,
    pageParams: PageParams,
    callback?: (data: PaginatedData<any>) => void
  ): Promise<any> => {
    try {
      const token = await getToken();
      if (!token) {
        throw new Error("User is not Authenticated")
      }
      const axiosInstance = createAxiosInstance(token);
      const response = await axiosInstance.get(toServerPath(path + pageParams.toString())); // Use the Axios instance
      if (callback) {
        callback(response.data); // Call the callback with the fetched data
      }
      return response.data; // Return the fetched data
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Optionally rethrow the error to handle it upstream
    }
  }, [getToken])

  const postData = useCallback(async (
    path: string,
    data: any,
    callback?: (responseData: any) => void
  ): Promise<boolean> => {
    const token = await getToken();
    if (!token) {
      throw new Error("User is not Authenticated")
    }
    const axiosInstance = createAxiosInstance(token);
    try {
      const response = await axiosInstance.post(toServerPath(path), data);
      if (callback) {
        callback(response.data);
      }
      return response.data
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  }, [getToken])

  const putData = useCallback(async (
    path: string,
    data: any,
    callback?: () => void
  ): Promise<void> => {
    const token = await getToken();
    if (!token) {
      throw new Error("User is not Authenticated")
    }
    const axiosInstance = createAxiosInstance(token);
    try {
      await axiosInstance.put(toServerPath(path), data);
      if (callback) {
        callback();
      }
    } catch (error) {
      console.error('Error putting data:', error);
      throw error;
    }
  }, [getToken])

  const patchData = useCallback(async (
    path: string,
    data: any,
    callback?: () => void
  ): Promise<void> => {
    const token = await getToken();
    if (!token) {
      throw new Error("User is not Authenticated")
    }
    const axiosInstance = createAxiosInstance(token);
    try {
      // Make the PATCH request
      await axiosInstance.patch(toServerPath(path), data);
      // Invoke the callback if provided
      if (callback) {
        callback();
      }
    } catch (error) {
      console.error('Error patching data:', error);
      throw error; // Rethrow the error so it can be handled by the caller
    }
  }, [getToken])

  const deleteData = useCallback(async (
    path: string,
    callback?: () => void,
    data?: any
  ): Promise<void> => {
    const token = await getToken();
    if (!token) {
      throw new Error("User is not Authenticated")
    }
    const axiosInstance = createAxiosInstance(token);
    try {
      await axiosInstance.delete(toServerPath(path), config(data));
      if (callback) {
        callback();
      }
    } catch (error) {
      console.error('Error deleting data:', error);
      throw error;
    }
  }, [getToken])

  const downloadFile = useCallback(async (
    path: string,
    filename: string,
    filetype: string,
  ) => {
    const axiosConfig: AxiosRequestConfig = {
      responseType: "blob",
    }
    const token = await getToken();
    if (!token) {
      throw new Error("User is not Authenticated")
    }
    const axiosInstance = createAxiosInstance(token);
    axiosInstance.get(toServerPath(path), axiosConfig)
      .then(
        (response) => {
          const fileURL = window.URL.createObjectURL(new File([new Blob([response.data])], filename, { type: filetype }))
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = filename
          alink.click();
        },
        (error) => {
          console.error(error)
        }
      )
  }, [getToken])

  return {
    headers,
    config,
    toServerPath,
    fetchItem,
    fetchData,
    postData,
    putData,
    patchData,
    deleteData,
    downloadFile
  }
}
