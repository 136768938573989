import { Box, HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { BORDER_DEFAULT, COLOUR_PRIMARY_LIGHT, COLOUR_SECONDARY_DARK, MARGIN_LARGE, RADIUS_PRIMARY } from '../../theme'

interface StatTileProps {
  title: string
  value: number
  format?: string
  units?: string
  testId?: string
}

export const StatTile = (props: StatTileProps) => {

  const toValueString = (value: number, format: string | undefined) => {
    if (value === undefined) {
      return "N/A"
    }
    if (format === "percentage") {
      return Number(props.value * 100).toFixed(1)
    }
    if (format === "decimal") {
      return value.toFixed(1)
    }
    return value.toString()
  }

  return (
    <Box
      h="100%"
      borderRadius={RADIUS_PRIMARY}
      p={MARGIN_LARGE}
      bg={COLOUR_PRIMARY_LIGHT}
      border={BORDER_DEFAULT}
    >
      <Text fontSize="xs">{props.title}</Text>
      <HStack justifyContent="unset">
        <Text
          test-id={props.testId}
          fontSize="xl"
        >{toValueString(props.value, props.format)}</Text>
        {props.units && <Text pl={3} fontSize="2xl" textColor={COLOUR_SECONDARY_DARK}>{props.units}</Text>}
      </HStack>
    </Box>
  )
}
