import { useRequests } from '../../../data/requests';
import React from 'react'
import { AnalyticaForm } from '../../form';
import { validateNonEmpty } from '../../../data/validations';
import { TextInput } from '../../form/input';
import { TextAreaInput } from '../../form/input/TextAreaInput';

export interface IssueReportModalProps {
}

export const IssueReportModal: React.FC<IssueReportModalProps> = () => {

  const { postIssueReport } = useRequests()

  return (
    <AnalyticaForm
      name="issue-report-modal"
      title="Report an Issue"
      description="Help us improve the system by providing some detailed information that will help us find and resolve the issue."
      initialValues={{}}
      mode='add'
      onSubmit={postIssueReport}
    >
      <AnalyticaForm.SingleItemForm>
        <TextInput
          name="title"
          title="Issue Title"
          testId='issue-title-input'
          validation={validateNonEmpty}
        />
        <TextInput
          name="pages_affected"
          title="Pages Affected"
          testId='issue-pages-affected-input'
          validation={validateNonEmpty}
        />
        <TextAreaInput
          name="description"
          title="Issue Description"
          testId='issue-description-input'
          validation={validateNonEmpty}
        />
      </AnalyticaForm.SingleItemForm>
    </AnalyticaForm >
  )
}
