import React, { useCallback, useContext, useState } from 'react';
import { useRequests } from '../../../data/requests';
import { ICON_ORG } from '../../../theme';
import { ModalContext } from '../../Modal';
import { OrgModal } from '../../modal/org';
import { AnalyticaTable } from '../../table';
import { ActionsMenu } from '../../table/action';
import { ActionMenuItem } from '../../table/action';
import { RTableColumn } from '../../table/column';

export const Orgs = () => {

  const { setModalContent } = useContext(ModalContext)
  const { fetchOrgs } = useRequests()
  const [reloadKey, setReloadKey] = useState(0)

  const load = useCallback(() => {
    setReloadKey(reloadKey + 1)
  }, [reloadKey])

  return (
    <AnalyticaTable
      title='Organisations'
      icon={ICON_ORG}
      fetchItems={fetchOrgs}
      defaultLimit={20}
      searchable
      searchOnChange
      fullscreen
    >
      <ActionsMenu>
        <ActionMenuItem
          onClick={() => setModalContent(
            <OrgModal
              mode="add"
              callback={load}
            />
          )}
          testId="new-org-button"
        >New Organisation</ActionMenuItem>
      </ActionsMenu>

      <RTableColumn id="name" name="Name" />
      <RTableColumn id="numClasses" name="#Classes" />
      <RTableColumn id="numStudents" name="#Students" />
      <RTableColumn id="primaryAddress" name="Primary Address" />

    </AnalyticaTable>
  )
}
