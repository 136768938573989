import { useFormikContext } from "formik";
import React, { forwardRef } from "react"
import { BORDER_PRIMARY_DARK, COLOUR_PRIMARY_WHITE, COLOUR_SECONDARY_DARK } from "../../theme";
import { ModalButton, AnalyticaButtonProps } from "./AnalyticaButton"

interface LoginButtonProps extends AnalyticaButtonProps { }

export const LoginButton = forwardRef<HTMLButtonElement, LoginButtonProps>((props, ref) => {

  const { submitForm } = useFormikContext();

  return (
    <ModalButton
      ref={ref}
      background={COLOUR_SECONDARY_DARK}
      textColor={COLOUR_PRIMARY_WHITE}
      border={BORDER_PRIMARY_DARK}
      onClick={submitForm}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      {...props}
    >Login</ModalButton>
  )
})

