import { Button } from "@chakra-ui/react"
import React, { forwardRef, KeyboardEvent, MouseEventHandler } from "react"
import { BORDER_DEFAULT, RADIUS_PRIMARY } from "../../theme"

export interface AnalyticaButtonProps {
  onClick?: MouseEventHandler<HTMLElement>
  clickOnEnter?: boolean
  background?: string
  textColor?: string
  border?: string
  isDisabled?: boolean
  children?: string
  isLoading?: boolean
  w?: string
  testId?: string
}

export const ModalButton = forwardRef<HTMLButtonElement, AnalyticaButtonProps>(({ clickOnEnter = false, ...props }, ref) => {

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (!clickOnEnter) {
      return
    }
    if (event.key === "Enter" && props.onClick) {
      props.onClick(event as unknown as React.MouseEvent<HTMLElement>)
    }
  }

  return (
    <Button
      ref={ref}
      onClick={props.onClick}
      onKeyDown={handleKeyDown}
      background={props.background}
      textColor={props.textColor}
      size="sm"
      fontSize="sm"
      border={props.border ? props.border : BORDER_DEFAULT}
      borderRadius={RADIUS_PRIMARY}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      w={props.w}
      test-id={props.testId}
    >
      {props.children}
    </Button>
  )
})
