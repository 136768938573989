import { Menu, MenuButton, MenuList } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import { BORDER_DEFAULT, COLOUR_PRIMARY_GREY, ICON_MENU, RADIUS_PRIMARY } from '../../../theme';

export interface ActionsMenuProps extends PropsWithChildren {
}

export const ActionsMenu = ({ ...props }: ActionsMenuProps) => {

  return <Menu size="sm" placement="bottom-start">
    {() => {
      return (
        <>
          <MenuButton
            test-id="actions-menu"
            p={2}
            border={BORDER_DEFAULT}
            borderRadius={RADIUS_PRIMARY}
            _hover={{ backgroundColor: COLOUR_PRIMARY_GREY }}
          >
            {ICON_MENU}
          </MenuButton>
          <MenuList>
            {props.children}
          </MenuList>
        </>
      );
    }}
  </Menu>
}
