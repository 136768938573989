import { FormControl } from "@chakra-ui/react"
import { FormikProps } from "formik"
import React, { PropsWithChildren } from "react"

interface ControlProps extends PropsWithChildren {
  form: FormikProps<any>
  name: string
  showErrors?: boolean
}

export const Control = ({ form, name, children, showErrors = true }: ControlProps) => (
  <FormControl isInvalid={showErrors && form.errors[name] && form.touched[name] ? true : false} >
    {children}
  </FormControl>
)
