import React, { ReactElement, useContext } from "react";
import { PageParams } from "../../../../data/api";
import { PaginatedData, Student } from "../../../../data/classes";
import { fullname } from '../../../../data/functions';
import { useRequests } from "../../../../data/requests";
import { ICON_STAFF } from "../../../../theme";
import { ModalContext } from "../../../Modal";
import { StudentModal } from "../../../modal/student";
import { AnalyticaTable } from "../../../table";
import { ActionsMenu } from "../../../table/action";
import { ActionMenuItem } from "../../../table/action/action-menu-item";
import { RTableColumn } from "../../../table/column";
import { RTableColumnProps } from "../../../table/column/r-table-column";
import { OrgTableTabProps } from "../org-profile";

export const OrgStudents: React.FC<OrgTableTabProps> = ({ ...props }) => {

  const { setModalContent } = useContext(ModalContext)
  const { fetchStudentsByOrgId } = useRequests()

  const fetchStudents = (setItems: (p: PaginatedData<Student>) => void, pageParams: PageParams) => {
    fetchStudentsByOrgId(props.org.id, setItems, pageParams);
  }

  const fullnameComponent = (_column: ReactElement<RTableColumnProps>, s: Student) => {
    return fullname(s)
  }

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchStudents}
        searchable
        searchOnChange
        sort="preferredName"
        to={"/students"}
      >
        <ActionsMenu>
          <ActionMenuItem
            testId="new-student-button"
            onClick={() => setModalContent(
              <StudentModal
                mode="add"
                org={props.org}
                callback={props.reload}
              />
            )}
          >New Student</ActionMenuItem>
        </ActionsMenu>

        <RTableColumn id="name" name="Name" component={fullnameComponent} />
        <RTableColumn id="level" name="Level" />
        <RTableColumn id="yearLevel" name="yearLevel" />
        <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
        <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />

      </AnalyticaTable>
    </>
  )
}
