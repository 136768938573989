import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react"
import { Field, FieldProps } from "formik"
import React from 'react'
import { BORDER_DEFAULT, COLOUR_PRIMARY_WHITE, RADIUS_PRIMARY } from "../../../theme"
import { Control } from "../control"
import { FormError } from "../error"
import { Label } from "./Label"
import { RANumberInputProps } from "./RANumberInputProps"

export interface RAIntegerInputProps extends RANumberInputProps { }

export const IntegerInput = ({ ...props }: RAIntegerInputProps) => {

  const name = props.name || IntegerInput.defaultProps.name

  return <Field
    name={name}
    validate={props.validation}>
    {({ field, form }: FieldProps) => (
      <Control
        form={form}
        name={name}
      >
        {props.title && <Label optional={props.optional || false} title={props.title} />}

        <NumberInput {...field}
          name={name}
          onChange={val => form.setFieldValue(field.name, val === "" ? "" : parseFloat(val))}
          min={props.min}
          max={props.max}
          background={COLOUR_PRIMARY_WHITE}
          size="sm"
          fontSize="sm"
        >
          <NumberInputField
            border={BORDER_DEFAULT}
            borderRadius={RADIUS_PRIMARY}
            test-id={props.testId}
            w={props.w || "full"}
          />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        <FormError
          form={form}
          name={name}
        />
      </Control>
    )}
  </Field>
}

IntegerInput.defaultProps = {
  type: "integer",
  name: "integer",
};
