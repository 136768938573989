import { Box, Heading, HStack, Spacer } from "@chakra-ui/react";
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { BORDER_DEFAULT, COLOUR_PRIMARY_WHITE, MARGIN_LARGE, RADIUS_PRIMARY } from "../../theme";
import { SearchBar } from "../search";
import { ActionsMenu } from "./action/actions-menu";
import { AnalyticaTableBody, AnalyticaTableBodyRef } from "./analytica-table-body";

export type AnalyticaTableRef = {
  reload: () => void;
};

export interface AnalyticaTableTabProps extends React.PropsWithChildren {
  title: string
  icon: ReactNode
  reload: () => void
  testId?: string
}

export interface AnalyticaTableProps extends React.PropsWithChildren {
  fetchItems: Function
  title?: string
  icon?: ReactNode
  render?: boolean
  searchable?: boolean
  searchOnChange?: boolean
  sort?: string
  order?: string
  defaultLimit?: number
  hidePagination?: boolean
  fullscreen?: boolean
  itemTestId?: Function
  to?: string
  scrollable?: boolean
  editable?: boolean
}

export const AnalyticaTable = (
  {
    searchable = false,
    searchOnChange = false,
    hidePagination = false,
    fullscreen = false,
    scrollable = false,
    editable = false,
    fetchItems,
    order,
    sort,
    defaultLimit,
    ...props
  }: AnalyticaTableProps) => {

  const [searchFn, setSearchFn] = useState<((text: string) => void) | null>(null);
  const tableBodyRef = useRef<AnalyticaTableBodyRef | null>(null);

  useEffect(() => {
    if (tableBodyRef.current) {
      setSearchFn(() => tableBodyRef.current?.search ?? (() => { console.error("Search function not available") }));
    }
  }, []);

  let searchBar = (
    <Box w="25%">
      {searchFn && (
        <SearchBar setSearch={searchFn} searchOnChange={searchOnChange} />
      )}
    </Box>
  );
  const actions = React.Children.toArray(props.children).filter(
    (child) => React.isValidElement(child) && child.type === ActionsMenu
  );

  return (
    <div>
      <Box
        test-id="table"
        w="full"
        h="full"
        borderRadius={RADIUS_PRIMARY}
        borderTopLeftRadius={fullscreen ? RADIUS_PRIMARY : 0}
        bg={COLOUR_PRIMARY_WHITE}
        border={BORDER_DEFAULT}
      >
        <Box
          p={MARGIN_LARGE}
        >
          <HStack>
            {props.title && <Heading size="sm">{props.title}</Heading>}
            <Spacer />
            {searchable && searchBar}
            {actions && actions}
          </HStack>
        </Box>
        <AnalyticaTableBody
          ref={tableBodyRef}
          fetchItems={fetchItems}
          icon={props.icon}
          render={props.render}
          searchable={searchable}
          searchOnChange={searchOnChange}
          sort={sort}
          order={order}
          defaultLimit={defaultLimit}
          hidePagination={hidePagination}
          fullscreen={fullscreen}
          itemTestId={props.itemTestId}
          to={props.to}
          scrollable={scrollable}
          editable={editable}
        >{props.children}</AnalyticaTableBody>
      </Box>
    </div>
  )
}
