import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Class, Org, Test } from "../../../data/classes";
import { datetimeTitle } from "../../../data/functions";
import { useRequests } from "../../../data/requests";
import { ICON_RESULT, ICON_STAFF } from "../../../theme";
import { useAccount } from "../../Account";
import { Loading } from "../../loading";
import { ModalContext } from "../../Modal";
import { TestModal } from "../../modal/test";
import { ActionsMenu } from "../../table/action";
import { ActionMenuItem } from "../../table/action/action-menu-item";
import { AnalyticaTableTabProps } from "../../table/analytica-table";
import { AnalyticaTableContainer } from "../../table/analytica-table-container";
import { RAProfile } from "../profile";
import { TestResults } from "./results";
import { TestStaff } from "./staff";

export interface TestTableTabProps extends AnalyticaTableTabProps {
  org: Org
  test: Test
}

export const TestProfile = () => {

  const { getCognitoId, getUserGroups } = useAccount()
  const {
    putTest,
    downloadTestPDF,
    fetchTestById,
    fetchClassById,
    fetchOrgById,
    fetchStaffMemberByCognitoId
  } = useRequests()
  const { setModalContent } = useContext(ModalContext)
  const [org, setOrg] = useState<Org>()
  const [clazz, setClass] = useState<Class>()
  const [test, setTest] = useState<Test>()
  let { testId } = useParams();

  const load = useCallback(async () => {
    // fetch student
    if (!testId) throw new Error("testId is undefined")
    const test = await fetchTestById(testId, setTest)
    // fetch cognito id
    const cognitoId = await getCognitoId()
    if (!cognitoId) {
      throw new Error("CognitoId Not Found")
    }
    const userGroups = await getUserGroups()
    if (userGroups.includes("admin")) {
      // nothing here, since admins can view all staff members
    } else if (userGroups.includes("staff")) {
      // staff members can only view classes in their org
      const currentUserStaffMember = await fetchStaffMemberByCognitoId(cognitoId, () => { })
      if (currentUserStaffMember.orgId !== test.orgId) {
        throw new Error("staff member does not have permissions to view this other staff member")
      }
    } else {
      throw new Error("User Role not found")
    }
    // fetch class and org
    fetchClassById(test.classId, setClass)
    fetchOrgById(test.orgId, setOrg)
  }, [
    testId,
    getCognitoId,
    getUserGroups,
    fetchStaffMemberByCognitoId,
    fetchTestById,
    fetchClassById,
    fetchOrgById
  ]);

  useEffect(() => {
    load()
  }, [load]);

  if (!org || !test || !clazz) {
    return <Loading />
  }

  const download = () => {
    let d = dayjs(new Date(test.created))
    downloadTestPDF(test.id, `${clazz.name}_${d.format("DDMMYY")}.pdf`)
  }

  const endTest = () => {
    test.completed = true
    putTest(test, () => load())
  }

  return (
    <>
      <RAProfile
        primary={datetimeTitle(test) || ''}
        secondary={org.name}
      >
        <ActionsMenu>
          <ActionMenuItem testId="edit" onClick={() => setModalContent(
            <TestModal mode="update" org={org} clazz={clazz} test={test} />
          )}>Edit</ActionMenuItem>
          <ActionMenuItem testId="delete" onClick={() => setModalContent(
            <TestModal mode="delete" org={org} clazz={clazz} test={test} />
          )}>Delete</ActionMenuItem>
          <ActionMenuItem testId="download-test" onClick={() => download()}>Download Test</ActionMenuItem>
          <ActionMenuItem testId="end-test" onClick={() => endTest()}>End Test</ActionMenuItem>
        </ActionsMenu>
      </RAProfile>

      <AnalyticaTableContainer>
        <TestResults
          title="Results"
          icon={ICON_RESULT}
          org={org}
          test={test}
          reload={load}
          testId="view-results"
        />
        <TestStaff
          title="Staff"
          icon={ICON_STAFF}
          org={org}
          test={test}
          reload={load}
          testId="view-staff"
        />
      </AnalyticaTableContainer>
    </>
  )
}
