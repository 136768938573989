import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { PageParams } from '../../../data/api';
import { Org, PaginatedData, StaffMember } from '../../../data/classes';
import { fullname } from '../../../data/functions';
import { useRequests } from '../../../data/requests';
import { ICON_STAFF } from '../../../theme';
import { useAccount } from '../../Account';
import { Loading } from '../../loading';
import { ModalContext } from '../../Modal';
import { StaffMemberModal } from '../../modal/staff-member';
import { AnalyticaTable } from '../../table';
import { ActionsMenu } from '../../table/action';
import { ActionMenuItem } from '../../table/action';
import { RTableColumn } from '../../table/column';
import { RTableColumnProps } from '../../table/column/r-table-column';

export const StaffMembers = () => {

  const { setModalContent } = useContext(ModalContext)
  const { getCognitoId } = useAccount()
  const { fetchOrgById, fetchStaffMemberByCognitoId, fetchStaffByOrgId } = useRequests()
  const [staffMember, setStaffMember] = useState<StaffMember | null>(null)
  const [org, setOrg] = useState<Org | null>();

  const load = useCallback(async () => {
    const cognitoId = await getCognitoId()
    if (!cognitoId) {
      throw new Error("CognitoId Not Found")
    }
    const staffMember = await fetchStaffMemberByCognitoId(cognitoId, setStaffMember)
    fetchOrgById(staffMember.orgId, setOrg)
  }, [getCognitoId, fetchStaffMemberByCognitoId, fetchOrgById]);

  useEffect(() => {
    load()
  }, [load]);

  if (!org || !staffMember) {
    return <Loading />
  }

  const fetchStaffMembers = (setItems: (s: PaginatedData<StaffMember>) => void, pageParams: PageParams) => {
    fetchStaffByOrgId(staffMember.orgId, setItems, pageParams);
  }

  const fullnameComponent = (_column: ReactElement<RTableColumnProps>, s: StaffMember) => {
    return fullname(s)
  }

  return (
    <AnalyticaTable
      title='Staff Members'
      icon={ICON_STAFF}
      fetchItems={fetchStaffMembers}
      defaultLimit={20}
      searchable
      searchOnChange
      fullscreen
    >
      <ActionsMenu>
        <ActionMenuItem
          testId='new-staff-member-button'
          onClick={() => setModalContent(
            <StaffMemberModal
              mode="add"
              org={org}
              staffMember={staffMember}
              callback={load}
            />
          )}
        >New Staff Member</ActionMenuItem>
      </ActionsMenu>

      <RTableColumn id="name" name="Name" component={fullnameComponent} />
      <RTableColumn id="email" name="Email Address" />
      <RTableColumn id="numClasses" name="#Classes" />
      <RTableColumn id="numStudents" name="#Students" />

    </AnalyticaTable>
  )
}
