import { Box, Card, CardHeader, Heading, Stack, Tag, TagCloseButton, TagLabel, Text } from "@chakra-ui/react"
import { Field, FieldProps } from "formik"
import React, { useCallback, useEffect, useState } from 'react'
import { BORDER_DEFAULT, COLOUR_PRIMARY_LIGHT, RADIUS_PRIMARY } from "../../../theme"
import { Control } from "../control"
import { validateNonEmpty, validateOptional } from "../../../data/validations"
import { Label } from "./Label"
import { RAInputProps } from "./RAInputProps"
import { Named, PaginatedData, Person } from "../../../data/classes"
import { FormError } from "../error"
import { SearchBar } from "../../search"
import { fullname } from "../../../data/functions"
import { PageParams } from "../../../data/api"

const R = require('ramda');

type Item = Named | Person

export interface MultiSelectionInputProps extends Omit<RAInputProps, 'validation'> {
  validation?: (arg0: string) => string | undefined
  initItems: (setItems: (p: PaginatedData<Item>) => void, pageParams: PageParams) => void
  fetchItems: (setItems: (p: PaginatedData<Item>) => void, pageParams: PageParams) => void
}

export const MultiSelectionInput = ({
  initItems,
  fetchItems,
  ...props
}: MultiSelectionInputProps) => {

  const [items, setItems] = useState<PaginatedData<Item>>();
  const [selectedItems, setSelectedItems] = useState<PaginatedData<Item>>();
  const [search, setSearch] = useState<string>("");

  const start = 0
  const limit = 25

  const name = props.name || MultiSelectionInput.defaultProps.name

  const isNamed = (obj: any): obj is Named => {
    return obj
      && typeof obj.name === "string";
  }

  const isPerson = (obj: any): obj is Person => {
    return obj
      && typeof obj.preferredName === "string"
      && typeof obj.lastName === "string";
  }

  const itemName = (item: Item): string => {
    if (isNamed(item)) {
      return item.name;
    } else if (isPerson(item)) {
      return fullname(item) || "N/A";
    }
    return "N/A"
  }

  const addSelection = (item: Item, field: string, setFieldValue: (field: string, value: any) => void) => {
    if (!selectedItems) {
      throw new Error("SelectedItems is undefined")
    }
    if (!selectedItems.data.includes(item)) {
      const updatedSelections = selectedItems
      updatedSelections.data = [...selectedItems.data, item];
      updatedSelections.total = selectedItems.total + 1
      setSelectedItems(updatedSelections);
      const selectedIds = updatedSelections.data.map((i: Item) => i.id)
      setFieldValue(field, selectedIds);
    }
  };

  const removeSelection = (item: Item, field: string, setFieldValue: (field: string, value: any) => void) => {
    if (!selectedItems) {
      throw new Error("SelectedItems is undefined")
    }
    const updatedSelections = selectedItems
    updatedSelections.data = selectedItems.data.filter((i: Item) => i.id !== item.id);
    updatedSelections.total = selectedItems.total - 1
    setSelectedItems(updatedSelections);
    const selectedIds = updatedSelections.data.map((i: Item) => i.id)
    setFieldValue(field, selectedIds);
  };

  const isSelected = (item: Item) => {
    return R.any((selected: Item) => selected.id === item.id)(selectedItems?.data)
  }

  const initializeSelected = useCallback(() => {
    const pageParams = new PageParams(start, -1, "", "", "")
    initItems(setSelectedItems, pageParams);
  }, [initItems, start])

  const loadItems = useCallback(() => {
    const loadData = async () => {
      const pageParams = new PageParams(start, limit, "", "", search)
      fetchItems(setItems, pageParams)
    };
    loadData()
    initializeSelected()
  }, [
    setItems,
    search,
    start,
    limit,
    fetchItems,
    initializeSelected
  ])

  useEffect(() => {
    loadItems()
  }, [loadItems]);

  return (

    <Field
      name={name}
      validate={props.validation || (props.optional ? validateOptional : validateNonEmpty)} >
      {({ field, form }: FieldProps) => (
        <Control
          form={form}
          name={name}
        >
          {props.title &&
            <Box>
              <Label optional={props.optional || false} title={props.title} />
            </Box>
          }
          <Box display="flex" flexWrap="wrap" gap={1}>
            {selectedItems?.data.map((item) => (
              <Tag
                mr={2}
                py={2}
                key={item.id}
                size="sm"
                borderRadius={RADIUS_PRIMARY}
                bg={COLOUR_PRIMARY_LIGHT}
                border={BORDER_DEFAULT}
              >
                <TagLabel>{itemName(item)}</TagLabel>
                <TagCloseButton
                  onClick={() => removeSelection(item, field.name, form.setFieldValue)}
                  test-id="remove-button"
                />
              </Tag>
            ))}
          </Box>

          <Box
            py={5}
            zIndex={1}
            position="relative"
            overflow="visible"
            test-id="multi-selection-search-bar"
          >
            <SearchBar
              setSearch={setSearch}
              searchOnChange
            />
          </Box>

          <Box >
            <Box
              height={300}
              overflowY="auto">
              <Stack>
                {items?.data.map((item) => (
                  <Card
                    size="sm"
                    key={item.id}
                    test-id="menu-item"
                    border={BORDER_DEFAULT}
                    onClick={() => !isSelected(item) && addSelection(item, field.name, form.setFieldValue)}
                    opacity={isSelected(item) ? 0.5 : 1} // Visual feedback for disabled state
                    pointerEvents={isSelected(item) ? "none" : "auto"}
                  >
                    <CardHeader>
                      <Heading size='sm'>{itemName(item)}</Heading>
                    </CardHeader>
                  </Card>
                ))}
                {items?.data.length === 0 && (
                  <Box
                    textAlign="center"
                    p={10}
                    color="gray.500"
                  >
                    <Text fontSize="sm">No options available</Text>
                  </Box>
                )}
              </Stack>
            </Box>
          </Box>

          <FormError
            form={form}
            name={name}
          />

        </Control>
      )
      }
    </Field >
  )
}

MultiSelectionInput.defaultProps = {
  type: "multi-selection",
  name: "multi-selection",
};
