import { Divider, Heading, Spacer, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BORDER_DEFAULT, COLOUR_PRIMARY_WHITE, MARGIN_LARGE, MARGIN_MEDIUM } from '../../theme';
import { useAccount } from '../Account';
import { AvatarMenu } from '../avatar-menu';
import { SidebarNavItem } from './sidebar-nav-item';

export const Sidebar = () => {

  const { getUserGroups } = useAccount()

  const [userGroups, setUserGroups] = useState<string[]>()

  useEffect(() => {
    const load = async () => {
      const ug = await getUserGroups()
      setUserGroups(ug)
    }
    load()
  }, [getUserGroups])

  return (
    <VStack spacing={MARGIN_LARGE} p={MARGIN_LARGE} bg={COLOUR_PRIMARY_WHITE} align="start" height="100vh" w="full">

      <VStack spacing={MARGIN_MEDIUM} align="start" w="full">
        <Heading p={MARGIN_LARGE} size="sm">Rubric Analytica</Heading>
        <Divider border={BORDER_DEFAULT} />
      </VStack >

      {userGroups?.includes("admin") &&
        <VStack spacing={MARGIN_MEDIUM} align="stretch" w="full">
          <SidebarNavItem to="/orgs" testId="org-nav">Organizations</SidebarNavItem>
          <SidebarNavItem to="/admins" testId="admin-nav">Admins</SidebarNavItem>
        </VStack >
      }

      {userGroups?.includes("staff") &&
        <VStack spacing={MARGIN_MEDIUM} align="stretch" w="full">
          <SidebarNavItem to={'/classes'} testId="classes-nav">Classes</SidebarNavItem>
          <SidebarNavItem to={'/students'} testId="students-nav"> Students</SidebarNavItem>
          <SidebarNavItem to={'/staff'} testId="staff-nav">Staff</SidebarNavItem>
          <SidebarNavItem to={'/tests'} testId="test-nav">Tests</SidebarNavItem>
        </VStack >
      }

      <Spacer />
      <VStack spacing={MARGIN_MEDIUM} align="stretch" w="full">
        <Divider border={BORDER_DEFAULT} />
        <AvatarMenu />
      </VStack >
    </VStack >
  )
}
