import { Tag } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { PageParams } from "../../../../data/api";
import { CreateTestRequest, PaginatedData, Test } from "../../../../data/classes";
import { datetimeTitle } from "../../../../data/functions";
import { useRequests } from "../../../../data/requests";
import { BORDER_DEFAULT, ICON_STAFF } from "../../../../theme";
import { AnalyticaTable } from "../../../table";
import { ActionsMenu } from "../../../table/action";
import { ActionMenuItem } from "../../../table/action/action-menu-item";
import { RTableColumn } from "../../../table/column";
import { RTableColumnProps } from "../../../table/column/r-table-column";
import { ClassTableTabProps } from "../class-profile";

export const ClassTests: React.FC<ClassTableTabProps> = ({ ...props }) => {

  const {
    fetchTestsByClassId,
    postTest
  } = useRequests()

  const fetchTests = (setItems: (p: PaginatedData<Test>) => void, pageParams: PageParams) => {
    fetchTestsByClassId(props.clazz.id, setItems, pageParams);
  }

  const generateTestForClass = (classId: string) => {
    try {
      // backend will fill out test details
      const createTestRequest = new CreateTestRequest(classId)
      postTest(createTestRequest, props.reload)
    } catch (error) {
      console.error(error)
    }
  }

  const testTitleComponent = (_column: ReactElement<RTableColumnProps>, t: Test) => {
    return datetimeTitle(t)
  }

  const testStatus = (_column: ReactElement<RTableColumnProps>, t: Test) =>
    <Tag
      size="sm"
      border={BORDER_DEFAULT}
      bg={t.completed ? "#F0FFF4" : "#FFF5EE"}
    >{t.completed ? "COMPLETED" : "PENDING"}</Tag>

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchTests}
        searchable
        searchOnChange
        to={"/tests"}
      >
        <ActionsMenu>
          <ActionMenuItem
            testId="generate-test-button"
            onClick={() => generateTestForClass(props.clazz.id)}
          >Generate Test</ActionMenuItem>
        </ActionsMenu>

        <RTableColumn id="datetime" name="Datetime" component={testTitleComponent} />
        <RTableColumn id="status" name="Status" component={testStatus} />
        <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
        <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
      </AnalyticaTable>
    </>
  )
}
