import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageParams } from '../../../data/api';
import { Class, Org, PaginatedData, StaffMember, Student } from '../../../data/classes';
import { useRequests } from '../../../data/requests';
import { validateMultiSelection, validateName, validateNonEmpty } from '../../../data/validations';
import { AnalyticaForm } from '../../form';
import { IntegerInput, MultiSelectionInput, TextInput } from '../../form/input';
import { ModalMode } from '../../Modal';

const R = require('ramda')

export type ClassModalMode = ModalMode | 'students' | 'add-existing-staff' | 'add-existing-students';

export interface ClassModalProps {
  mode: ClassModalMode
  org: Org
  staffMember?: StaffMember
  clazz?: Class
  callback?: () => void
}

export const ClassModal: React.FC<ClassModalProps> = ({ mode, clazz, ...props }) => {

  const navigate = useNavigate()

  const deleteAndReturn = async (clazz: Class, callback: () => void) => {
    deleteClass(clazz, callback)
    navigate(-1)
  }

  const [students, setStudents] = useState<PaginatedData<Student>>();
  const {
    fetchStaffByClassId,
    fetchStaffByOrgId,
    fetchStudentsByClassId,
    fetchStudentsByOrgId,
    postClass,
    deleteClass,
    putClass,
    putStudent,
    patchClassStudents,
    patchClassStaff
  } = useRequests()

  const fetchStaffForClass = (setItems: (p: PaginatedData<StaffMember>) => void, pageParams: PageParams) => {
    if (clazz === undefined) {
      throw new Error("Class is undefined, cannot fetch students")
    }
    fetchStaffByClassId(clazz.id, setItems, pageParams);
  }

  const fetchDropdownStaff = (setItems: (p: PaginatedData<StaffMember>) => void, pageParams: PageParams) => {
    fetchStaffByOrgId(props.org.id, setItems, pageParams);
  }

  const fetchStudentsForClass = (setItems: (p: PaginatedData<Student>) => void, pageParams: PageParams) => {
    if (clazz === undefined) {
      throw new Error("Class is undefined, cannot fetch students")
    }
    fetchStudentsByClassId(clazz.id, setItems, pageParams);
  }

  const fetchDropdownStudents = (setItems: (p: PaginatedData<Student>) => void, pageParams: PageParams) => {
    fetchStudentsByOrgId(props.org.id, setItems, pageParams);
  }

  const updateEachStudentInClass = (values: any, callback: () => void) => {
    if (!clazz) {
      throw new Error("Class prop not supplied")
    }

    // const findUpdatedItems = (reference: Student[], updated: Student[]): Student[] =>
    //   R.differenceWith(R.eqProps("id"), updated, reference);

    // Apply Ramda's filter function to keep only updated items
    const updated = R.filter(
      (item: Student) => !students?.data.some((ref: Student) => R.eqProps("id", ref, item)
        && ref.identifier === item.identifier
        && ref.preferredName === item.preferredName
        && ref.lastName === item.lastName
        && ref.yearLevel === item.yearLevel
      ),
      values.students
    );
    // patch updated students
    if (updated && updated.length > 0) {
      R.forEach((s: Student) => {
        putStudent(s, () => { })
      }, updated)
    }
    callback?.();
  }

  const updateStaffListForClass = (values: any, callback: () => void) => {
    if (!clazz) {
      throw new Error("Class prop not supplied")
    }
    patchClassStaff(clazz.id, values.staff, callback)
  }

  const putStudentListForClass = (values: any, callback: () => void) => {
    if (!clazz) {
      throw new Error("Class prop not supplied")
    }
    patchClassStudents(clazz.id, values.students, callback)
  }

  useEffect(() => {
    if (mode !== 'students') {
      return
    }
    if (clazz === undefined) {
      throw new Error("Class is undefined, cannot fetch students")
    }
    const pageParams = new PageParams(0, -1, "", "", "")
    fetchStudentsByClassId(clazz.id, setStudents, pageParams);
  }, [clazz, clazz?.id, mode, fetchStudentsByClassId]);

  let title = ""
  let description = ""
  let initialValues = {}

  switch (mode) {
    case "add":
      title = "Add a new Class"
      description = `Add a new Class to ${props.org.name}`
      initialValues = {
        name: "",
        yearLevel: "",
        orgId: props.org.id,
        staff: props.staffMember && [props.staffMember.id]
      }
      break
    case "update":
      if (!clazz) {
        throw new Error("Item not supplied");
      }
      title = "Edit Class"
      description = "Update Class Details."
      initialValues = clazz
      break
    case "delete":
      if (!clazz) {
        throw new Error("Item not supplied");
      }
      title = "Delete Class"
      description = `Are you sure you want to delete Class - ${clazz.name}?`
      initialValues = { id: clazz.id }
      break
    case "students":
      if (!clazz) {
        throw new Error("Item not supplied");
      }
      title = "Edit Class Students"
      description = `Edit multiple students in Class - ${clazz.name}?`
      initialValues = { students: students || [] }
      break
  }

  return (
    <AnalyticaForm
      name='class-modal'
      title={title}
      description={description}
      initialValues={initialValues}
      mode={(mode === 'students'
        || mode === 'add-existing-staff'
        || mode === 'add-existing-students')
        ? 'update' : mode}
      onSubmit={
        // TODO: Rewrite this fucking mess.
        mode === 'add' ? postClass :
          mode === 'update' ? putClass
            : mode === 'delete' ? deleteAndReturn
              : mode === 'students' ? updateEachStudentInClass
                : mode === 'add-existing-staff' ? updateStaffListForClass
                  : mode === 'add-existing-students' ? putStudentListForClass
                    : () => { }}
      callback={props.callback}
    >
      {
        R.includes(mode, ['add', 'update']) &&
        <AnalyticaForm.SingleItemForm>
          <TextInput
            name="name"
            title="Name"
            testId='class-name-input'
            validation={validateName}
          />
          <Box pt={4} />
          <IntegerInput
            name="yearLevel"
            title="Year Level"
            testId='class-year-level-input'
            validation={validateNonEmpty}
            min={1}
            max={12}
          />
        </AnalyticaForm.SingleItemForm>
      }
      {
        mode === 'students' &&
        <AnalyticaForm.TableForm<Student>
          fieldKey="students"
        >
          <TextInput
            name="identifier"
            title="Identifier"
            testId='student-identifier-input'
            validation={validateNonEmpty}
          />
          <TextInput
            name="preferredName"
            title="Preferred Name"
            testId='student-preferred-name-input'
            validation={validateName}
          />
          <TextInput
            name="lastName"
            title="Last Name"
            testId='student-last-name-input'
            validation={validateName}
          />
          <IntegerInput
            name="yearLevel"
            title="Year Level"
            testId='student-year-level-input'
            validation={validateNonEmpty}
            min={1}
            max={12}
          />
        </AnalyticaForm.TableForm>
      }
      {
        mode === 'add-existing-staff' &&
        <AnalyticaForm.SingleItemForm>
          <MultiSelectionInput
            name="staff"
            title="Staff"
            testId='class-staff-input'
            validation={validateMultiSelection}
            initItems={fetchStaffForClass}
            fetchItems={fetchDropdownStaff}
          />
        </AnalyticaForm.SingleItemForm>
      }
      {
        mode === 'add-existing-students' &&
        <AnalyticaForm.SingleItemForm>
          <MultiSelectionInput
            name="students"
            title="Students"
            testId='class-student-input'
            validation={validateMultiSelection}
            initItems={fetchStudentsForClass}
            fetchItems={fetchDropdownStudents}
          />
        </AnalyticaForm.SingleItemForm>
      }

    </AnalyticaForm >
  )
}
