import { FormErrorMessage } from "@chakra-ui/react"
import React from "react"

interface FormErrorProps {
  form: any
  name: string
  showErrors?: boolean
}

export const FormError = ({ form, name, showErrors = true }: FormErrorProps) => (
  <FormErrorMessage fontSize="xs">{(showErrors && form.errors && form.errors[name]) ? form.errors[name]?.toString() : ""}</FormErrorMessage>
)
