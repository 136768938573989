import React, { useContext } from "react";
import { PageParams } from "../../../../data/api";
import { Class, PaginatedData } from "../../../../data/classes";
import { useRequests } from "../../../../data/requests";
import { ICON_CLASS } from "../../../../theme";
import { ModalContext } from "../../../Modal";
import { ClassModal } from "../../../modal/class";
import { AnalyticaTable } from "../../../table";
import { ActionsMenu } from "../../../table/action";
import { ActionMenuItem } from "../../../table/action/action-menu-item";
import { RTableColumn } from "../../../table/column";
import { OrgTableTabProps } from "../org-profile";

const OrgClasses: React.FC<OrgTableTabProps> = ({ ...props }) => {

  const { setModalContent } = useContext(ModalContext)
  const { fetchClassesByOrgId } = useRequests()

  const fetchClasses = (setItems: (c: PaginatedData<Class>) => void, pageParams: PageParams) =>
    fetchClassesByOrgId(props.org.id, setItems, pageParams);

  return (
    <>
      <AnalyticaTable
        icon={ICON_CLASS}
        fetchItems={fetchClasses}
        searchable
        searchOnChange
        sort="name"
        to={"/classes"}
      >
        <ActionsMenu>
          <ActionMenuItem
            testId="new-class-button"
            onClick={() => setModalContent(
              <ClassModal
                mode="add"
                org={props.org}
                callback={props.reload}
              />
            )}
          >New Class</ActionMenuItem>
        </ActionsMenu>

        <RTableColumn id="name" name="Name" />
        <RTableColumn id="yearLevel" name="Year Level" />
        <RTableColumn id="numStudents" name="#Students" />
        <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
        <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />

      </AnalyticaTable>
    </>
  )
}
export default OrgClasses
