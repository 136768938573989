import { Textarea } from "@chakra-ui/react"
import { Field, FieldProps } from "formik"
import React from 'react'
import { BORDER_DEFAULT, COLOUR_PRIMARY_WHITE, RADIUS_PRIMARY } from "../../../theme"
import { Control } from "../control"
import { validateNonEmpty, validateOptional } from "../../../data/validations"
import { Label } from "./Label"
import { RAInputProps } from "./RAInputProps"
import { FormError } from "../error"

export interface TextAreaInputProps extends Omit<RAInputProps, 'validation'> {
  validation?: (arg0: string) => string | undefined
}

export const TextAreaInput = ({ ...props }: TextAreaInputProps) => {

  const name = props.name || TextAreaInput.defaultProps.name

  return (
    <Field
      name={name}
      validate={props.validation || (props.optional ? validateOptional : validateNonEmpty)}>
      {({ field, form }: FieldProps) => (
        <Control
          form={form}
          name={name}
        >
          {props.title && <Label optional={props.optional || false} title={props.title} />}
          <Textarea
            {...field}
            name={name}
            test-id={props.testId}
            background={COLOUR_PRIMARY_WHITE}
            border={BORDER_DEFAULT}
            borderRadius={RADIUS_PRIMARY}
            w={props.w || "full"}
          />

          <FormError
            form={form}
            name={name}
          />

        </Control>
      )}
    </Field>
  )
}

TextAreaInput.defaultProps = {
  name: "text"
};
