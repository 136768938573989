// thought this could possibly be undefined too
type idstr = string

export abstract class ID {
  id: idstr;

  constructor(id: idstr) {
    this.id = id
  }
}

export abstract class OptionalID {
  id?: idstr;
}

export abstract class DateTimeID extends ID {
  datetime: string;

  constructor(id: idstr, datetime: string) {
    super(id)
    this.datetime = datetime
  }
}

export abstract class Person extends ID {
  preferredName: string;
  lastName: string;

  constructor(id: idstr, preferredName: string, lastName: string) {
    super(id)
    this.preferredName = preferredName
    this.lastName = lastName
  }
}

export abstract class Named extends ID {
  name: string;

  constructor(id: idstr, name: string) {
    super(id)
    this.name = name
  }
}

export class User extends Person {
  email: string;
  cognitoId: string;

  constructor(id: idstr, preferredName: string, lastName: string, cognitoId: string, email: string) {
    super(id, preferredName, lastName)
    this.cognitoId = cognitoId
    this.email = email
  }
}

export class Admin extends User { }

export class StaffMember extends User {
  orgId: string;
  numClasses: number;
  numStudents: number;

  constructor(id: idstr, preferredName: string, lastName: string, cognitoId: string, email: string, orgId: string, numClasses: number, numStudents: number) {
    super(id, preferredName, lastName, cognitoId, email)
    this.orgId = orgId
    this.numClasses = numClasses
    this.numStudents = numStudents
  }
}

export class Student extends Person {
  orgId: string
  identifier: string;
  yearLevel: number;
  level: number;
  avgScore: number;
  avgTimeRemaining: number;
  numClasses: number;
  numTestsCompleted: number;
  classes: string[];

  constructor(id: idstr, preferredName: string, lastName: string, orgId: string, identifier: string, yearLevel: number, level: number, avgScore: number, avgTimeRemaining: number, numClasses: number, numTestsCompleted: number, classes: string[]) {
    super(id, preferredName, lastName)
    this.orgId = orgId
    this.identifier = identifier
    this.yearLevel = yearLevel
    this.level = level
    this.avgScore = avgScore
    this.avgTimeRemaining = avgTimeRemaining
    this.numClasses = numClasses
    this.numTestsCompleted = numTestsCompleted
    this.classes = classes
  }
}

export class Org extends Named {
  primaryAddress: string;
  avgScore: number;
  avgTimeRemaining: number;
  numClasses: number;
  numStudents: number;
  numTestsCompleted: number;

  constructor(id: idstr, name: string, primaryAddress: string, avgScore: number, avgTimeRemaining: number, numClasses: number, numStudents: number, numTestsCompleted: number) {
    super(id, name)
    this.primaryAddress = primaryAddress
    this.avgScore = avgScore
    this.avgTimeRemaining = avgTimeRemaining
    this.numClasses = numClasses
    this.numStudents = numStudents
    this.numTestsCompleted = numTestsCompleted
  }
}

export class Class extends Named {
  orgId: string;
  avgScore: number;
  avgTimeRemaining: number;
  numStudents: number;
  numTestsCompleted: number;
  students: string[];

  constructor(id: idstr, name: string, orgId: string, avgScore: number, avgTimeRemaining: number, numStudents: number, numTestsCompleted: number, students: string[]) {
    super(id, name)
    this.orgId = orgId
    this.avgScore = avgScore
    this.avgTimeRemaining = avgTimeRemaining
    this.numStudents = numStudents
    this.numTestsCompleted = numTestsCompleted
    this.students = students
  }
}

export class CreateTestRequest {
  classId: idstr;
  constructor(
    classId: string,
  ) {
    this.classId = classId
  }
}

export class Test extends DateTimeID {
  classId: idstr;
  created: string;
  completed: boolean;

  constructor(
    id: idstr,
    datetime: string,
    classId: string,
    created: string,
    completed: boolean
  ) {
    super(id, datetime)
    this.classId = classId
    this.created = created
    this.completed = completed
  }
}

export class Result extends OptionalID {
  orgId: idstr;
  classId: idstr;
  testId: idstr;
  studentId: idstr;
  identifier: string;
  completed: boolean;
  score: number;
  timeRemaining: number;
  active: boolean;
  name?: string;
  level?: number;

  constructor(
    orgId: idstr,
    classId: idstr,
    testId: idstr,
    studentId: idstr,
    identifier: string,
    completed: boolean,
    score: number,
    timeRemaining: number,
    active: boolean,
    name?: string,
    level?: number,
    id?: idstr,
  ) {
    super()
    this.orgId = orgId
    this.classId = classId
    this.testId = testId
    this.studentId = studentId
    this.identifier = identifier
    this.score = score
    this.timeRemaining = timeRemaining
    this.completed = completed
    this.active = active
    this.name = name
    this.level = level
    this.id = id
  }
}

export class StudentAndResult {
  student: Student;
  result: Result;

  constructor(student: Student, result: Result) {
    this.student = student
    this.result = result
  }
}

export class FeedbackReport extends DateTimeID {
  classId: idstr;
  studentId: idstr;
  created: string;
  body: string;
  studentName: string;

  constructor(
    id: idstr,
    datetime: string,
    classId: string,
    studentId: string,
    created: string,
    body: string,
    studentName: string,
  ) {
    super(id, datetime)
    this.classId = classId
    this.studentId = studentId
    this.created = created
    this.body = body
    this.studentName = studentName
  }
}

// TODO: This way of nesting objects should probs be replicatedi
// in the other classes, but would just need to make sure that 
// we are being efficient with data transmission.
//
export class FeedbackReportBatch extends DateTimeID {
  feedbackReports: FeedbackReport[];

  constructor(
    id: idstr,
    datetime: string,
    feedbackReports: FeedbackReport[],
  ) {
    super(id, datetime)
    this.feedbackReports = feedbackReports
  }
}

export class DashboardAggregation {
  avgProgress: ProgressData[];
  topLearners: LearnerData[];
  totalClasses: number;
  totalStudents: number;
  testsLastMonth: number;
  avgLevel: number;
  avgScore: number;
  avgTimeRemaining: number;

  constructor(
    avgProgress: ProgressData[],
    topLearners: LearnerData[],
    totalClasses: number,
    totalStudents: number,
    testsLastMonth: number,
    avgLevel: number,
    avgScore: number,
    avgTimeRemaining: number,
  ) {
    this.avgProgress = (avgProgress ?? []).map(child => new ProgressData(child));
    this.topLearners = (topLearners ?? []).map(child => new LearnerData(child));
    this.totalClasses = totalClasses
    this.totalStudents = totalStudents
    this.testsLastMonth = testsLastMonth
    this.avgLevel = avgLevel
    this.avgScore = avgScore
    this.avgTimeRemaining = avgTimeRemaining
  }
}

export class ProgressData {
  datetime: string;
  level: number;
  score: number;
  timeRemaining: number;

  constructor(data: Partial<ProgressData>) {
    this.datetime = data.datetime ?? ''
    this.level = data.level ?? 0
    this.score = data.score ?? 0
    this.timeRemaining = data.timeRemaining ?? 0
  }
}

export class LearnerData {
  rank: number;
  preferredName: string;
  lastName: string;
  growthRate: number;

  constructor(data: Partial<LearnerData>) {
    this.rank = data.rank ?? 0
    this.preferredName = data.preferredName ?? ''
    this.lastName = data.lastName ?? ''
    this.growthRate = data.growthRate ?? 0
  }
}

export class IssueReport extends ID {
  reporterId: idstr;
  title: string;
  pagesAffected: string;
  description: string;

  constructor(id: idstr, reporterId: idstr, title: string, pagesAffected: string, description: string) {
    super(id)
    this.reporterId = reporterId
    this.title = title
    this.pagesAffected = pagesAffected
    this.description = description
  }
}

export class PaginatedData<T> {
  data: T[];
  total: number;

  constructor(data: T[], total: number) {
    this.data = data;
    this.total = total;
  }
}
