import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Org, Student } from "../../../data/classes";
import { fullname } from "../../../data/functions";
import { useRequests } from "../../../data/requests";
import { ICON_CLASS, ICON_TEST } from "../../../theme";
import { useAccount } from "../../Account";
import { Loading } from "../../loading";
import { ModalContext } from "../../Modal";
import { StudentModal } from "../../modal/student";
import { StatTile } from "../../stats";
import { ActionsMenu } from "../../table/action";
import { ActionMenuItem } from "../../table/action/action-menu-item";
import { AnalyticaTableTabProps } from "../../table/analytica-table";
import { AnalyticaTableContainer } from "../../table/analytica-table-container";
import { RAProfile } from "../profile";
import { StudentClasses } from "./classes";
import { StudentTests } from "./tests";

export interface StudentTableTabProps extends AnalyticaTableTabProps {
  org: Org
  student: Student
  callback?: () => void
}

export const StudentProfile = () => {

  const { getCognitoId, getUserGroups } = useAccount()
  const { fetchStudentById, fetchOrgById, fetchStaffMemberByCognitoId } = useRequests()
  const { setModalContent } = useContext(ModalContext)
  const [student, setStudent] = useState<Student | null>()
  const [org, setOrg] = useState<Org | null>()
  let { studentId } = useParams();

  const load = useCallback(async () => {
    // fetch student
    if (!studentId) throw new Error("studentId is undefined")
    const student = await fetchStudentById(studentId, setStudent)
    // fetch cognito id
    const cognitoId = await getCognitoId()
    if (!cognitoId) {
      throw new Error("CognitoId Not Found")
    }
    const userGroups = await getUserGroups()
    if (userGroups.includes("admin")) {
      // nothing here, since admins can view all students
    } else if (userGroups.includes("staff")) {
      // staff members can only view classes in their org
      const staffMember = await fetchStaffMemberByCognitoId(cognitoId, () => { })
      if (staffMember.orgId !== student.orgId) {
        throw new Error("staff member does not have permissions to view this student")
      }
    } else {
      throw new Error("User Role not found")
    }
    // fetch org
    fetchOrgById(student.orgId, setOrg)
  }, [
    studentId,
    getCognitoId,
    getUserGroups,
    fetchStaffMemberByCognitoId,
    fetchStudentById,
    fetchOrgById
  ]);

  useEffect(() => {
    load()
  }, [load]);

  if (!org || !student) {
    return <Loading />
  }

  return (
    <>
      <RAProfile
        primary={fullname(student) || ''}
        secondary={org.name}
      >
        <ActionsMenu>
          <ActionMenuItem testId="edit" onClick={() => setModalContent(
            <StudentModal mode="update" student={student} org={org} callback={load} />
          )}>Edit</ActionMenuItem>
          <ActionMenuItem testId="delete" onClick={() => setModalContent(
            <StudentModal mode="delete" student={student} org={org} callback={load} />
          )}>Delete</ActionMenuItem>
          <ActionMenuItem testId="edit-student-classes-button" onClick={() => setModalContent(
            <StudentModal mode='classes' org={org} student={student} callback={load} />
          )}>Edit Classes</ActionMenuItem>
        </ActionsMenu>
        <StatTile
          title="Student Level"
          testId="student-level-tile"
          value={student.level}
        />
        <StatTile
          title="Total Active Classes"
          value={student.numClasses}
          testId="total-active-classes-tile"
        />
        <StatTile
          title="Tests Completed"
          value={student.numTestsCompleted}
          testId="tests-completed-tile"
        />
        <StatTile
          title="Average Score Percentage"
          value={student.avgScore}
          units="%"
          format="percentage"
          testId="average-score-tile"
        />
        <StatTile
          title="Average Time Remaining"
          value={student.avgTimeRemaining}
          units="s"
          format="decimal"
          testId="average-time-remaining-tile"
        />
      </RAProfile>

      <AnalyticaTableContainer>
        <StudentClasses
          title="Classes"
          icon={ICON_CLASS}
          org={org}
          student={student}
          reload={load}
          testId="view-classes"
        />
        <StudentTests
          title="Tests"
          icon={ICON_TEST}
          org={org}
          student={student}
          reload={load}
          testId="view-tests"
        />
        {/* <StudentFeedbackReports title="Feedback Reports" icon={ICON_REPORT} org={org} student={student} callback={() => load()} testId="view-feedback-reports" /> */}
      </AnalyticaTableContainer>
    </>
  )
}
