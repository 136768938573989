import { useCallback } from 'react';
import { PageParams, useApi } from './api';
import { Admin, Class, CreateTestRequest, DashboardAggregation, FeedbackReport, FeedbackReportBatch, IssueReport, Org, PaginatedData, Result, StaffMember, Student, Test } from './classes';

export const useRequests = () => {

  const {
    fetchData,
    fetchItem,
    postData,
    putData,
    patchData,
    deleteData,
    downloadFile,
  } = useApi()

  // Get Item

  const fetchStudentById = useCallback(
    async (studentId: string, setState: (s: Student) => void) => {
      return fetchItem(`v1/students/${studentId}`, setState)
    }, [fetchItem])

  const fetchTestById = useCallback(
    async (testId: string, setState: (t: Test) => void) => {
      return fetchItem(`v1/tests/${testId}`, setState)
    }, [fetchItem])

  const fetchResultById = useCallback(
    async (resultId: string, setState: (r: Result) => void) => {
      return fetchItem(`v1/results/${resultId}`, setState)
    }, [fetchItem])

  const fetchAdminById = useCallback(
    async (adminId: string, setState: (a: Admin) => void) => {
      return fetchItem(`v1/admins/${adminId}`, setState)
    }, [fetchItem])

  const fetchAdminByCognitoId = useCallback(
    async (cognitoId: string, setState: (a: Admin) => void) => {
      return fetchItem(`v1/admins/cognito/${cognitoId}`, setState)
    }, [fetchItem])

  const fetchStaffMemberByCognitoId = useCallback(
    async (cognitoId: string, setState: (s: StaffMember) => void) => {
      return fetchItem(`v1/staff/cognito/${cognitoId}`, setState)
    }, [fetchItem])

  const fetchStaffMemberById = useCallback(
    async (staffId: string, setState: (s: StaffMember) => void) => {
      return fetchItem(`v1/staff/${staffId}`, setState)
    }, [fetchItem])

  const fetchOrgById = useCallback(
    async (orgId: string, setState: (o: Org) => void) => {
      return fetchItem(`v1/orgs/${orgId}`, setState)
    }, [fetchItem])

  const fetchClassById = useCallback(
    async (classId: string, setState: (c: Class) => void) => {
      return fetchItem(`v1/classes/${classId}`, setState)
    }, [fetchItem])

  const fetchFeedbackReportBatchById = useCallback(
    async (feedbackReportBatchId: string, setState: (f: FeedbackReportBatch) => void) => {
      return fetchItem(`v1/feedback-report-batches/${feedbackReportBatchId}`, setState)
    }, [fetchItem])

  const fetchFeedbackReportById = useCallback(
    async (feedbackReportId: string, setState: (f: FeedbackReport) => void) => {
      return fetchItem(`v1/feedback-reports/${feedbackReportId}`, setState)
    }, [fetchItem])

  // Get Data

  const fetchOrgs = useCallback(
    async (setState: (p: PaginatedData<Org>) => void, pageParams: PageParams) => {
      fetchData("v1/orgs", pageParams, setState)
    }, [fetchData])

  const fetchClassesByOrgId = useCallback(
    async (orgId: string, setState: (p: PaginatedData<Class>) => void, pageParams: PageParams) => {
      fetchData(`v1/orgs/${orgId}/classes`, pageParams, setState)
    }, [fetchData])

  const fetchClassesByStaffMemberId = useCallback(
    async (staffMemberId: string, setState: (p: PaginatedData<Class>) => void, pageParams: PageParams) => {
      fetchData(`v1/staff/${staffMemberId}/classes`, pageParams, setState)
    }, [fetchData])

  const fetchClassesByStudentId = useCallback(
    async (studentId: string, setState: (p: PaginatedData<Class>) => void, pageParams: PageParams) => {
      fetchData(`v1/students/${studentId}/classes`, pageParams, setState)
    }, [fetchData])

  const fetchAdmins = useCallback(
    async (setState: (p: PaginatedData<Admin>) => void, pageParams: PageParams) => {
      fetchData(`v1/admins`, pageParams, setState)
    }, [fetchData])

  const fetchStaffByOrgId = useCallback(
    async (orgId: string, setState: (p: PaginatedData<StaffMember>) => void, pageParams: PageParams) => {
      fetchData(`v1/orgs/${orgId}/staff`, pageParams, setState)
    }, [fetchData])


  const fetchStaffByClassId = useCallback(
    async (classId: string, setState: (p: PaginatedData<StaffMember>) => void, pageParams: PageParams) => {
      fetchData(`v1/classes/${classId}/staff`, pageParams, setState)
    }, [fetchData])

  const fetchStaffByTestId = useCallback(
    async (testId: string, setState: (p: PaginatedData<StaffMember>) => void, pageParams: PageParams) => {
      fetchData(`v1/tests/${testId}/staff`, pageParams, setState)
    }, [fetchData])

  const fetchStudentsByOrgId = useCallback(
    async (orgId: string, setState: (p: PaginatedData<Student>) => void, pageParams: PageParams) => {
      fetchData(`v1/orgs/${orgId}/students`, pageParams, setState)
    }, [fetchData])

  const fetchStudentsByClassId = useCallback(
    async (classId: string, setState: (p: PaginatedData<Student>) => void, pageParams: PageParams) => {
      fetchData(`v1/classes/${classId}/students`, pageParams, setState)
    }, [fetchData])

  const fetchStudentsByStaffMemberId = useCallback(
    async (staffMemberId: string, setState: (p: PaginatedData<Student>) => void, pageParams: PageParams) => {
      fetchData(`v1/staff/${staffMemberId}/students`, pageParams, setState)
    }, [fetchData])

  const fetchTests = useCallback(
    async (setState: (p: PaginatedData<Test>) => void, pageParams: PageParams) => {
      fetchData(`v1/tests`, pageParams, setState)
    }, [fetchData])

  const fetchTestsByClassId = useCallback(
    async (classId: string, setState: (p: PaginatedData<Test>) => void, pageParams: PageParams) => {
      fetchData(`v1/classes/${classId}/tests`, pageParams, setState)
    }, [fetchData])

  const fetchTestsByStudentId = useCallback(
    async (studentId: string, setState: (p: PaginatedData<Test>) => void, pageParams: PageParams) => {
      fetchData(`v1/students/${studentId}/tests`, pageParams, setState)
    }, [fetchData])

  const fetchTestsByStaffMemberId = useCallback(
    async (staffMemberId: string, setState: (p: PaginatedData<Test>) => void, pageParams: PageParams) => {
      fetchData(`v1/staff/${staffMemberId}/tests`, pageParams, setState)
    }, [fetchData])

  const fetchResults = useCallback(
    async (setState: (p: PaginatedData<Result>) => void, pageParams: PageParams) => {
      fetchData(`v1/results`, pageParams, setState)
    }, [fetchData])

  const fetchResultsByTestId = useCallback(
    async (testId: string, setState: (p: PaginatedData<Result>) => void, pageParams: PageParams) => {
      fetchData(`v1/tests/${testId}/results`, pageParams, setState)
    }, [fetchData])

  const fetchResultsByStudentId = useCallback(
    async (studentId: string, setState: (p: PaginatedData<Result>) => void, pageParams: PageParams) => {
      fetchData(`v1/students/${studentId}/results`, pageParams, setState)
    }, [fetchData])

  const fetchDashboardAggregations = useCallback(
    async (setState: (p: PaginatedData<DashboardAggregation>) => void, pageParams: PageParams) => {
      fetchData(`v1/dashboard`, pageParams, setState)
    }, [fetchData])

  const fetchFeedbackReportBatchesByClassId = useCallback(
    async (classId: string, setState: (p: PaginatedData<FeedbackReportBatch>) => void, pageParams: PageParams) => {
      fetchData(`v1/classes/${classId}/feedback-report-batches`, pageParams, setState)
    }, [fetchData])

  const fetchFeedbackReportsByFeedbackReportBatchId = useCallback(
    async (feedbackReportBatchId: string, setState: (p: PaginatedData<FeedbackReport>) => void, pageParams: PageParams) => {
      fetchData(`v1/feedback-report-batches/${feedbackReportBatchId}/feedback-reports`, pageParams, setState)
    }, [fetchData])

  //
  // TODO: These few functions may be unneccessary. We should be able to re-use the existing ones with -1 limit or such.
  //
  const fetchAllStudentsByClassId = useCallback(
    async (classId: string): Promise<PaginatedData<Student>> => {
      try {
        const pageParams = new PageParams(0, -1, "", "", "")
        return fetchData(`v1/classes/${classId}/students`, pageParams)
      } catch (error) {
        console.error('Failed to fetch students:', error);
        return new PaginatedData<Student>([], 0);
      }
    }, [fetchData])

  const fetchAllResultsByTestId = useCallback(
    async (testId: string): Promise<PaginatedData<Result>> => {
      try {
        const pageParams = new PageParams(0, -1, "", "", "")
        return fetchData(`v1/tests/${testId}/results`, pageParams)
      } catch (error) {
        console.error('Failed to fetch results:', error);
        return new PaginatedData<Result>([], 0);
      }
    }, [fetchData])

  const fetchAllFeedbackReportsByFeedbackReportBatchId = useCallback(
    async (feedbackReportBatchId: string): Promise<PaginatedData<FeedbackReport>> => {
      try {
        const pageParams = new PageParams(0, -1, "", "", "")
        return fetchData(`v1/feedback-report-batches/${feedbackReportBatchId}/feedback-reports`, pageParams)
      } catch (error) {
        console.error('Failed to fetch feedback reports:', error);
        return new PaginatedData<FeedbackReport>([], 0);
      }
    }, [fetchData])

  const fetchAllFeedbackReportsByStudentId = useCallback(
    async (studentId: string): Promise<PaginatedData<FeedbackReport>> => {
      try {
        const pageParams = new PageParams(0, -1, "", "", "")
        return fetchData(`v1/students/${studentId}/feedback-reports`, pageParams)
      } catch (error) {
        console.error('Failed to fetch feedback reports:', error);
        return new PaginatedData<FeedbackReport>([], 0);
      }
    }, [fetchData])

  //

  // Post 

  const postOrg = useCallback(
    async (o: Org, callback: () => void) => {
      postData(`v1/orgs`, [o], callback)
    }, [postData])

  const postClass = useCallback(
    async (c: Class, callback: () => void) => {
      postData(`v1/classes`, [c], callback)
    }, [postData])

  const postAdmin = useCallback(
    async (a: Admin, callback: () => void) => {
      postData(`v1/admins/invite`, [a], callback)
    }, [postData])

  const postStaffMember = useCallback(
    async (s: StaffMember, callback: () => void) => {
      postData(`v1/staff/invite`, [s], callback)
    }, [postData])

  const postStudents = useCallback(
    async (s: Student[], callback: () => void) => {
      await postData(`v1/students`, s, callback)
    }, [postData])

  const postResult = useCallback(
    async (r: Result, callback: () => void) => {
      postData(`v1/results`, [r], callback)
    }, [postData])

  const postTest = useCallback(
    async (createTestRequest: CreateTestRequest, callback: () => void) => {
      postData(`v1/tests`, [createTestRequest], callback)
    }, [postData])

  const generateFeedbackReportBatchForClass = useCallback(
    async (classId: string, callback: () => void) => {
      postData(`v1/classes/${classId}/feedback-report-batches`, {}, callback)
    }, [postData])

  const generateFeedbackReportForStudent = useCallback(
    async (studentId: string, callback: () => void) => {
      postData(`v1/students/${studentId}/feedback-reports`, {}, callback)
    }, [postData])

  const postIssueReport = useCallback(
    async (i: IssueReport, callback: () => void) => {
      postData(`v1/issue-reports`, [i], callback)
    }, [postData])


  // Put

  const putOrg = useCallback(
    async (o: Org, callback: () => void) => {
      putData(`v1/orgs/${o.id}`, o, callback)
    }, [putData])

  const putClass = useCallback(
    async (c: Class, callback: () => void) => {
      putData(`v1/classes/${c.id}`, c, callback)
    }, [putData])

  const putAdmin = useCallback(
    async (a: Admin, callback: () => void) => {
      putData(`v1/admins/${a.id}`, a, callback)
    }, [putData])

  const putStaffMember = useCallback(
    async (s: StaffMember, callback: () => void) => {
      putData(`v1/staff/${s.id}`, s, callback)
    }, [putData])

  const putStudent = useCallback(
    async (s: Student, callback: () => void) => {
      putData(`v1/students/${s.id}`, s, callback)
    }, [putData])

  const putTest = useCallback(
    async (t: Test, callback: () => void) => {
      putData(`v1/tests/${t.id}`, t, callback)
    }, [putData])

  const putResult = useCallback(
    async (r: Result, callback: () => void) => {
      putData(`v1/results/${r.id}`, r, callback)
    }, [putData])


  // Patch

  const patchStudentClasses = useCallback(
    async (studentId: string, ids: string[], callback: () => void) => {
      patchData(`v1/students/${studentId}/classes`, ids, callback)
    }, [patchData])

  const patchClassStudents = useCallback(
    async (classId: string, ids: string[], callback: () => void) => {
      patchData(`v1/classes/${classId}/students`, ids, callback)
    }, [patchData])

  const patchClassStaff = useCallback(
    async (classId: string, ids: string[], callback: () => void) => {
      patchData(`v1/classes/${classId}/staff`, ids, callback)
    }, [patchData])

  // Delete

  // const deleteStudentClasses = useCallback(
  //   async (studentId: string, removed: string[]) => {
  //     deleteData(`v1/students/${studentId}/classes`, undefined, { classIds: removed })
  //   }, [deleteData])

  const deleteAdmin = useCallback(
    async (admin: Admin, callback: () => void) => {
      deleteData(`v1/orgs/${admin.id}`, callback)
    }, [deleteData])

  const deleteOrg = useCallback(
    async (org: Org, callback: () => void) => {
      deleteData(`v1/orgs/${org.id}`, callback)
    }, [deleteData])

  const deleteClass = useCallback(
    async (clazz: Class, callback: () => void) => {
      deleteData(`v1/classes/${clazz.id}`, callback)
    }, [deleteData])

  const deleteStaffMember = useCallback(
    async (staffMember: StaffMember, callback: () => void) => {
      deleteData(`v1/staff/${staffMember.id}`, callback)
    }, [deleteData])

  const deleteStudent = useCallback(
    async (student: Student, callback: () => void) => {
      deleteData(`v1/students/${student.id}`, callback)
    }, [deleteData])

  const deleteTest = useCallback(
    async (test: Test, callback: () => void) => {
      deleteData(`v1/tests/${test.id}`, callback)
    }, [deleteData])

  // Download

  const downloadPDF = useCallback(
    async (path: string, filename: string) => {
      downloadFile(path, filename, 'application/pdf')
    }, [downloadFile])

  const downloadCSV = useCallback(
    async (path: string, filename: string) => {
      downloadFile(path, filename, 'text/csv')
    }, [downloadFile])

  const downloadTestPDF = useCallback(
    async (testId: string, filename: string) => {
      downloadPDF(`v1/tests/${testId}/pdf`, filename)
    }, [downloadPDF])

  const downloadImportTemplateCSV = useCallback(
    async (filename: string) => {
      downloadCSV('v1/template/import', filename)
    }, [downloadCSV])

  return {
    fetchStudentById,
    fetchTestById,
    fetchResultById,
    fetchAdminById,
    fetchAdminByCognitoId,
    fetchStaffMemberByCognitoId,
    fetchStaffMemberById,
    fetchOrgById,
    fetchClassById,
    fetchFeedbackReportBatchById,
    fetchFeedbackReportById,
    fetchOrgs,
    fetchClassesByOrgId,
    fetchClassesByStaffMemberId,
    fetchClassesByStudentId,
    fetchAdmins,
    fetchStaffByOrgId,
    fetchStaffByClassId,
    fetchStaffByTestId,
    fetchStudentsByOrgId,
    fetchStudentsByClassId,
    fetchStudentsByStaffMemberId,
    fetchTests,
    fetchTestsByClassId,
    fetchTestsByStudentId,
    fetchTestsByStaffMemberId,
    fetchResults,
    fetchResultsByTestId,
    fetchResultsByStudentId,
    fetchDashboardAggregations,
    fetchFeedbackReportBatchesByClassId,
    fetchFeedbackReportsByFeedbackReportBatchId,
    fetchAllStudentsByClassId,
    fetchAllResultsByTestId,
    fetchAllFeedbackReportsByFeedbackReportBatchId,
    fetchAllFeedbackReportsByStudentId,
    postOrg,
    postClass,
    postAdmin,
    postStaffMember,
    postStudents,
    postResult,
    postTest,
    generateFeedbackReportBatchForClass,
    generateFeedbackReportForStudent,
    postIssueReport,
    putOrg,
    putClass,
    putAdmin,
    putStaffMember,
    putStudent,
    putTest,
    putResult,
    patchStudentClasses,
    patchClassStudents,
    patchClassStaff,
    // deleteStudentClasses,
    deleteAdmin,
    deleteOrg,
    deleteClass,
    deleteStaffMember,
    deleteStudent,
    deleteTest,
    downloadTestPDF,
    downloadImportTemplateCSV,
    downloadPDF,
    downloadCSV,
  }
}

