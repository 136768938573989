import { createStandaloneToast, ToastProps } from "@chakra-ui/react";

const { toast } = createStandaloneToast();

export const RAToast = (props: ToastProps) => {
  toast({
    duration: 5000,
    isClosable: true,
    position: "top-right",
    ...props,
  });
};
