import { IconButton, NumberInput, NumberInputField, Text } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { PaginatedData, Result, Student } from "../../../../data/classes";
import { fullname } from "../../../../data/functions";
import { useRequests } from "../../../../data/requests";
import { BORDER_DEFAULT, COLOUR_PRIMARY_LIGHT, COLOUR_SECONDARY_LIGHT, ICON_CROSS, ICON_RESULT, ICON_TICK } from "../../../../theme";
import { AnalyticaTable } from "../../../table";
import { RTableColumn } from "../../../table/column";
import { RTableColumnProps } from "../../../table/column/r-table-column";
import { TestTableTabProps } from "../test-profile";

export const TestResults: React.FC<TestTableTabProps> = ({ ...props }) => {

  const { fetchAllResultsByTestId, fetchAllStudentsByClassId, postResult, putResult } = useRequests()

  const fetchAndMergeData = async (setItems: (p: PaginatedData<Result>) => void) => {
    try {
      // Fetch data asynchronously
      const students = await fetchAllStudentsByClassId(props.test.classId);
      const results = await fetchAllResultsByTestId(props.test.id);
      // Merge data
      const items: Result[] = students.data.map((student: Student) => {
        const result = results.data.find((result: Result) => result.studentId === student.id);
        let item = new Result(
          student.orgId,
          props.test.classId,
          props.test.id,
          student.id,
          student.identifier,
          result?.completed || false,
          result?.score || 0,
          result?.timeRemaining || 0,
          result?.active || true,
          fullname(student),
          student.level,
          result?.id
        )
        return item
      });
      // Update state or items
      const pd = new PaginatedData<Result>(items, items.length)
      setItems(pd);
    } catch (error) {
      console.error('Error fetching or merging data:', error);
      setItems(new PaginatedData<Result>([], 0));
    }
  }

  const toggleCompleted = (
    e: React.MouseEvent,
    item: any,
    items: any[],
    setItems: (items: PaginatedData<Result>) => void
  ) => {
    // This should be used to stop the row click event occuring too.
    // Altough I have also turned off row clicking for this table.
    e.stopPropagation()
    item.completed = !item.completed
    updateData(item, items, setItems)
  }

  const updateData = async (
    item: Result,
    _items: Result[],
    setItems: (items: PaginatedData<Result>) => void
  ) => {
    let clone = { ...item }
    delete clone.name
    delete clone.level
    // TODO: Handle errors occurring in these requests
    if (!item.id) {
      await postResult(clone, () => fetchAndMergeData(setItems))
    } else {
      await putResult(clone, () => fetchAndMergeData(setItems))
    }
  }

  const toggle = (
    _column: ReactElement<RTableColumnProps>,
    item: Result,
    items: Result[],
    setItems: (items: PaginatedData<Result>) => void
  ): ReactElement => {
    let value = item.completed
    return <IconButton
      size="xs"
      icon={value ? ICON_TICK : ICON_CROSS}
      bg={value ? COLOUR_SECONDARY_LIGHT : COLOUR_PRIMARY_LIGHT}
      test-id="toggle-completed"
      border={BORDER_DEFAULT}
      onClick={(e: React.MouseEvent) => toggleCompleted(e, item, items, setItems)}
      aria-label=""
    />
  }

  const trueOrFalse = (
    _column: ReactElement<RTableColumnProps>,
    item: any,
  ): ReactElement => {
    let value = item.completed
    return <Text>{value ? "Yes" : "No"}</Text>
  }

  const displayScore = (
    _column: ReactElement<RTableColumnProps>,
    item: any,
  ): ReactElement => {
    let value = item.score * 25
    return <Text>{value}</Text>
  }

  const value = (column: ReactElement<RTableColumnProps>, value: any) => {
    if (value === undefined) {
      return "-"
    }
    if (typeof value === 'boolean') {
      return value
    }
    if (value === "true") {
      return true
    }
    if (value === "false") {
      return false
    }
    if (isNaN(value)) {
      return value
    }
    if (column.props.format === "percentage") {
      return `${Number(value * 100).toFixed(1)}%`
    }
    if (Number.isInteger(value)) {
      return value
    }
    return Number(value).toFixed(2)
  }

  const field = (column: ReactElement<RTableColumnProps>, item: any) => {
    if (column.props.id === undefined) {
      throw new Error("column is missing an id")
    }
    let v = item[column.props.id]
    return value(column, v)
  }

  const editableScore = (
    column: ReactElement<RTableColumnProps>,
    item: Result,
    items: Result[],
    setItems: (p: PaginatedData<Result>) => void
  ): ReactElement => {
    let v = field(column, item)
    let displayedValue = Math.round(v * 25);
    if (!item.completed) {
      return <>-</>
    }
    return (
      <NumberInput
        size="xs"
        w="100px"
        defaultValue={displayedValue}
        min={0}
        max={25}
        onChange={(e) => {
          let numValue = parseInt(e)
          if (isNaN(numValue)) {
            numValue = 0; // Prevent NaN issues
          }
          numValue = Math.min(25, Math.max(0, numValue)); // Enforce min/max
          item.score = numValue / 25
          updateData(item, items, setItems)
        }}
        onFocus={(e) => {
          e.target.select(); // Select text when focused
        }}
      >
        <NumberInputField />
      </NumberInput>

    )
  }

  const editableTimeRemaining = (
    column: ReactElement<RTableColumnProps>,
    item: Result,
    items: Result[],
    setItems: (p: PaginatedData<Result>) => void
  ): ReactElement => {
    let v = field(column, item)
    if (!item.completed) {
      return <>-</>
    }
    return (
      <NumberInput
        size="xs"
        w="100px"
        defaultValue={v}
        min={0}
        onChange={(e) => {
          let numValue = parseInt(e)
          if (isNaN(numValue)) {
            numValue = 0; // Prevent NaN issues
          }
          numValue = Math.max(0, numValue); // Enforce min/max
          item.timeRemaining = numValue
          updateData(item, items, setItems)
        }}
        onFocus={(e) => {
          e.target.select(); // Select text when focused
        }}
      >
        <NumberInputField />
      </NumberInput>

    )
  }

  // TODO: Possibly also add an undo button.
  return (
    <>
      <AnalyticaTable
        icon={ICON_RESULT}
        fetchItems={fetchAndMergeData}
        hidePagination
        scrollable
        editable
      >
        <RTableColumn id="name" name="Name" isClickable={false} />
        <RTableColumn id="identifier" name="Identifier" isClickable={false} />
        <RTableColumn id="level" name="Level" isClickable={false} />
        <RTableColumn id="completed"
          name={!props.test.completed ? "Completed (Click to Toggle)" : "Completed"}
          component={!props.test.completed ? toggle : trueOrFalse}
          isClickable={false}
        />
        <RTableColumn id="score" name="Score (Out of 25)"
          component={!props.test.completed ? editableScore : displayScore}
          isClickable={false}
        />
        <RTableColumn id="timeRemaining" name="Time Remaining (s)"
          component={!props.test.completed ? editableTimeRemaining : undefined}
          isClickable={false}
        />
      </AnalyticaTable>
    </>
  )
}
