import { Box, Button, ModalBody, ModalContent, ModalHeader, Text } from '@chakra-ui/react';
import React, { useState } from 'react'
import { useApi } from '../../../data/api';
import { Class, Org, Student } from '../../../data/classes';
import { useRequests } from '../../../data/requests';
import { validateName, validateNonEmpty } from '../../../data/validations';
import { BORDER_DEFAULT, COLOUR_PRIMARY_DARK } from '../../../theme';
import { AnalyticaForm } from '../../form';
import { IntegerInput, TextInput } from '../../form/input';
import { RAToast } from '../../toast/toast';
import { CSVUpload } from '../../upload/csv';

export interface ImportModalProps {
  org: Org
  clazz: Class
  callback: () => void
}

export const ImportModal: React.FC<ImportModalProps> = ({ ...props }) => {

  const [students, setStudents] = useState<Student[]>()
  const { downloadImportTemplateCSV } = useRequests()
  const { postData } = useApi()

  let title = "Import Students from CSV File"
  let description = `Add a new Student to ${props.clazz.name} at ${props.org.name}.`

  const save = async (values: { students: Student[] | undefined }, callback: () => void) => {
    if (values.students === undefined) {
      throw new Error("Students should not be undefined");
    }
    // Map over students to add the necessary fields
    const studentsToSubmit = values.students.map((s) => ({
      ...s,
      level: 1,
      orgId: props.org.id,
      classes: [props.clazz.id],
    }));
    try {
      await postData(`v1/students`, studentsToSubmit,
        () => {
          RAToast({
            title: "All students imported successfully",
            description: "All students have been added to the system.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        })

    } catch (error) {
      if (error instanceof Error) {
        // Handle failure and show the error toast
        RAToast({
          title: "Error Importing Students",
          description: "Students could not be imported. Please ensure that no entries already exist in the system.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
    callback()
  };

  const download = () => {
    downloadImportTemplateCSV("template.csv")
  }

  let importStudentsFromCSV =
    <ModalContent borderRadius={10} border={BORDER_DEFAULT}>

      <ModalHeader>{title}</ModalHeader>

      <ModalBody>
        <Text>{description}</Text>
        <Box pt={10} />
        <Button variant='link' textColor={COLOUR_PRIMARY_DARK} onClick={download} >Download CSV Template</Button>
        <Box h={5} />
        <CSVUpload setData={setStudents} />
        <Box pt={10} />
      </ModalBody>

    </ModalContent>

  const tableForm =
    <AnalyticaForm
      name='student-import-modal'
      title={title}
      description={description}
      initialValues={{ students: students }}
      mode={'add'}
      onSubmit={save}
      callback={props.callback}
    >
      <AnalyticaForm.TableForm<Student>
        fieldKey="students"
        isEditAddRemoveEnabled
      >
        <TextInput
          name="identifier"
          title="Identifier"
          testId='student-identifier-input'
          validation={validateNonEmpty}
        />
        <TextInput
          name="preferredName"
          title="Preferred Name"
          testId='student-preferred-name-input'
          validation={validateName}
        />
        <TextInput
          name="lastName"
          title="Last Name"
          testId='student-last-name-input'
          validation={validateName}
        />
        <IntegerInput
          name="yearLevel"
          title="Year Level"
          testId='class-year-level-input'
          validation={validateNonEmpty}
          min={1}
          max={12}
        />
      </AnalyticaForm.TableForm>
    </AnalyticaForm>

  return !students || students.length === 0 ? importStudentsFromCSV : tableForm
}
