import React from "react";
import { PageParams } from "../../../../data/api";
import { PaginatedData, StaffMember } from "../../../../data/classes";
import { fullname } from '../../../../data/functions';
import { useRequests } from "../../../../data/requests";
import { ICON_STAFF } from "../../../../theme";
import { AnalyticaTable } from "../../../table";
import { RTableColumn } from "../../../table/column";
import { TestTableTabProps } from "../test-profile";

export const TestStaff: React.FC<TestTableTabProps> = ({ ...props }) => {

  const { fetchStaffByTestId } = useRequests()

  const fetchStaff = (setItems: (p: PaginatedData<StaffMember>) => void, pageParams: PageParams) => {
    fetchStaffByTestId(props.test.id, setItems, pageParams);
  }

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchStaff}
        searchable
        searchOnChange
        sort="preferredName"
        to={"/staff"}
      >
        <RTableColumn id="name" name="Name" component={fullname} />
        <RTableColumn id="email" name="Email Address" />
        <RTableColumn id="numClasses" name="#Classes" />
        <RTableColumn id="numStudents" name="#Students" />
      </AnalyticaTable>
    </>
  )
}
